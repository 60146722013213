import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Tooltip from '../tooltip/Tooltip';
import { IoIosHelpCircle } from "react-icons/io";
import Header from "../Header";
import Footer from "../Footer";
import { AES, enc } from "crypto-js";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import RecentItems from "../recentItems/RecentItems";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { parse, format } from "date-fns";
import DatePicker from "react-datepicker";
import { API_URL } from "../config";
import "./shipment.css"
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
// import {  useHistory } from 'react-router-dom';
function ContractShippmentDetails() {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const params = useParams();
  const ids = params.id;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [datas, setDatas] = useState([]);
  const [datas1, setDatas1] = useState([]);
  const [history, setHistory] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tcf, setTcf] = useState([]);
  const [data, setData] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [product, setProduct] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [check, setCheck1] = useState(false);
  const [contractShipment, setContractShipment] = useState([]);
  const [contractShipmentLength, setContractShipmentLength] = useState("");
  const [endDateError, setEndDateError] = useState("");
  // <Link
  //                                       to={`/contract-shipment/detail/${y.id}`}
  //                                     >
  //                                       {y.contract_ship_code}
  //                                     </Link>

  const handleReload = (parentShipId) => {
    navigate(`/shipment/contract-shipment-details/${parentShipId}`);
    window.location.reload();
  };

  useEffect(() => {
    fetchCompany();
  }, [decryptedAccessToken]);


  const fetchCompany = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/contract", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.contract;
      setCompanies(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);

  const [one, setOne] = useState(new Date());
  const ones =
    one.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    one.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    one.toLocaleDateString("en-US", { day: "2-digit" });
  const Date1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      name="laycan_to_date"
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [two, setTwo] = useState(new Date());
  const twos =
    two.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    two.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    two.toLocaleDateString("en-US", { day: "2-digit" });
  const Date2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      name="laycan_from_date"
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [getPrice, setPrice] = useState([]);
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/price_items", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });

      if (response.status === 200 && response.data?.priceitem) {
        const data = response.data.priceitem;
        setPrice(data);
        console.log(data);
      } else {
        console.error("Invalid response structure or no data found.");
      }
    } catch (error) {
      console.error("Error fetching price items:", error);
    }
  };

  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    }).format(number);
  };
   

  const renderShipmentQty = (tcfValues) => {
      if (tcfValues > 0) {
          return <span className='sr'>{formatNumber(tcfValues)}</span>;
      } else if (tcfValues < 0) {
          return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
      } else {
          return <span className='sr'>{formatNumber(tcfValues)}</span>;
      }
  };
  

  const [fromDate, setFromDate] = useState(null);

  const [existingContractShipCodes, setExistingContractShipCodes] = useState(
    []
  );
  const [contractID, setContractID] = useState([]);
  const [tcfList, setTcfList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [laycanDates, setLaycanDate] = useState([]);
  const [laycanToDates, setLaycanToDate] = useState([]);





  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL + "get/contract_shipbyID/" + ids,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      
      if (response.status === 200) {
        const data = response.data;
        console.log("***********",response);
        setExistingContractShipCodes(
          data.contract_details[0]?.contract_ship_code || ""
        );
        const contract_shipID = data.contract_details[0]?.contract_id || null;
        setContractID(contract_shipID);
        setDatas(data.contract_details);
        setLaycanDate(data.contract_details[0].laycan_from_date || null);
        setLaycanToDate(data.contract_details[0].laycan_to_date || null);
        setDatas1(data.contract_details[0] || {});
        setContractShipment(data.child_contract_shipments || []);
        setContractShipmentLength(
          data.child_contract_shipments?.length || 0
        );

        console.log(data);
        setOrders(data.orders || []);
        setProduct(data.products || []);
        setTcfList(data.tcf || []);
        setHistory(data.history || []);

        // Initialize DataTables after DOM is ready
        $(document).ready(function () {
          setTimeout(() => {
            $("#tables").DataTable();
            $("#tcf").DataTable();
            $("#orders").DataTable();
            $("#historys").DataTable();
            $("#product").DataTable();
            $("#contract-ship").DataTable();
          }, 500);
        });

        // Parsing laycan dates
        const startDates = data.contract_details[0]?.laycan_from_date
          ? parse(
              data.contract_details[0].laycan_from_date,
              "dd/MM/yyyy",
              new Date()
            )
          : null;
        setStartDate(startDates);

        const endDates = data.contract_details[0]?.laycan_to_date
          ? parse(
              data.contract_details[0].laycan_to_date,
              "dd/MM/yyyy",
              new Date()
            )
          : null;
        setEndDate(endDates);
      } else {
        toast.error("No Data Available", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [ids, decryptedAccessToken]);

  setTimeout(() => {
    $("#tables").DataTable();
    $("#tcf").DataTable();
    $("#orders").DataTable();
    $("#historys").DataTable();
    $("#product").DataTable();
    $("#contract-ship").DataTable();
  }, 500);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       API_URL + "get/contract_shipbyID/" + ids,
  //       {
  //         headers: { Authorization: `Bearer ${decryptedAccessToken}` },
  //       }
  //     );
  //     console.log("***********"+response);
  //     if (response.status === 200) {

  //       const data = response.data;
  //       setExistingContractShipCodes(
  //         data.contract_details[0].contract_ship_code
  //       );
  //       const contract_shipID = response.data.contract_details[0].contract_id;
  //       setContractID(contract_shipID);
  //       setDatas(data.contract_details);
  //       setLaycanDate(data.contract_details[0].laycan_from_date);
  //       setLaycanToDate(data.contract_details[0].laycan_to_date);
  //       setDatas1(data.contract_details[0]);
  //       setContractShipment(data.child_contract_shipments);
  //       setContractShipmentLength(data.child_contract_shipments.length);

  //       console.log(data);
  //       setOrders(data.orders);
  //       setProduct(data.products);
  //       setTcfList(data.tcf);
  //       setHistory(data.history);

  //       $(document).ready(function () {
  //         // $('#tables').DataTable().destroy();
  //         setTimeout(() => {
  //           $("#tables").DataTable();
  //           $("#tcf").DataTable();
  //           $("#orders").DataTable();
  //           $("#historys").DataTable();
  //           $("#product").DataTable();
  //           $("#contract-ship").DataTable();
  //         }, 500);
  //       });

  //       const startDates = data.contract_details[0].laycan_from_date
  //         ? parse(
  //             data.contract_details[0].laycan_from_date,
  //             "dd/MM/yyyy",
  //             new Date()
  //           )
  //         : null;
  //       setStartDate(startDates);
  //       const endDates = data.contract_details[0].laycan_to_date
  //         ? parse(
  //             data.contract_details[0].laycan_to_date,
  //             "dd/MM/yyyy",
  //             new Date()
  //           )
  //         : null;
  //       setEndDate(endDates);
  //     } else {
  //       toast.error("No Data Available", {
  //         position: "bottom-center",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, [ids, decryptedAccessToken]);

  // setTimeout(() => {
  //   $("#tables").DataTable();
  //   $("#tcf").DataTable();
  //   $("#orders").DataTable();
  //   $("#historys").DataTable();
  //   $("#product").DataTable();
  //   $("#contract-ship").DataTable();
  // }, 500);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (!startDate) {
      setEndDateError("Please select a Laycan From Date	.");
    } else {
      const diffInTime = date.getTime() - startDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays !== 10) {
        // setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
      } else {
        setEndDateError("");
      }
    }
  };

  const noOfshipment = () => {
    navigate("/shippment/contract-shippment-details-lists/" + ids, {
      state: { number_of_shipment: datas[0].no_of_shipments },
    });
  };

  $("#tables").DataTable();
  $("#tcf").DataTable();
  $("#orders").DataTable();
  $("#history").DataTable();
  $("#product").DataTable();
  $("#contract-ship").DataTable();

  //  edit
  const handleEditClick = () => {
    $("#tables").DataTable();
    $("#tcf").DataTable();
    $("#orders").DataTable();
    $("#history").DataTable();
    $("#product").DataTable();
    $("#contract-ship").DataTable();
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [lockedOne, setLockedOne] = useState({});
  const check_locked = datas1.locked;

  useEffect(() => {
    setLockedOne(check_locked); // Update lockeds with the value of tt
  }, [check_locked]); // Run this effect whenever tt changes

  useEffect(() => {}, [lockedOne]); // Run this effect whenever lockeds changes

  const handleCheckboxLocked = (e) => {
    setLockedOne(e.target.checked);
    // setLockeds(!lockeds)
    // Debug log
  };

  const [isChildPresent, setIsChildPresent] = useState(false);

  const parentShipment = datas1.parent_shipment;
  const childShipment = datas1.contract_ship_code;

  useEffect(() => {
    // Assuming existingContractShipCodes is an array of existing contract_ship_codes
    if (parentShipment && childShipment) {
      const isChildAlreadyExists =
        existingContractShipCodes.includes(childShipment);

      setIsChildPresent(isChildAlreadyExists);
    }
  }, [parentShipment, childShipment, existingContractShipCodes]);

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };

  const handleSaveEdit = (e) => {
    $("#tables").DataTable();
    $("#tcf").DataTable();
    $("#orders").DataTable();
    $("#history").DataTable();
    $("#contract-ship").DataTable();
    $("#product").DataTable();
    e.preventDefault();
    const responseData = {
      currency: datas1.currency,
      exchange_rate: datas1.exchange_rate,
      contract_shipment_status: datas1.contract_shipment_status,
      record_type: datas1.record_type,
      contract: datas1.contract,
      laycan_year: datas1.laycan_year,
      parent_shipment: datas1.parent_shipment,
      order_quantity: datas1.order_quantity,
      shipping_term: datas1.shipping_term,
      price: datas1.price,
      price_type: datas1.price_type,
      price_basis: datas1.price_basis,
      sales_price_margin: datas1.sales_price_margin,
      price_pmt_in_words: datas1.price_pmt_in_words,
      quantity_type: datas1.quantity_type,
      quantity: datas1.quantity,
      quantity_from: datas1.quantity_from,
      quantity_to: datas1.quantity_to,
      no_of_shipments: datas1.no_of_shipments,
      child_shipment_count: datas1.child_shipment_count,
      shipment_quantity_tolerance: datas1.shipment_quantity_tolerance,
      tcf_count: datas1.tcf_count,
      product_count: datas1.product_count,
      no_of_orders: datas1.no_of_orders,
      quantity_info: datas1.quantity_info,
      laycan_year_from: datas1.laycan_year_from,
      laycan_year_to: datas1.laycan_year_to,
      laycan_month_from: datas1.laycan_month_from,
      laycan_month_to: datas1.laycan_month_to,
      laycan_from_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
      laycan_to_date: endDate ? format(endDate, "dd/MM/yyyy") : null,
      shipment_month: datas1.shipment_month,
      laycan: datas1.laycan,
      shipment_quantity: datas1.shipment_quantity,
      laycan_month: datas1.laycan_month,
      laycan_year: datas1.laycan_year,
    };
    console.log(JSON.stringify(responseData));
    // setIsSubmitted(true)
    axios
      .put(`${API_URL}update/contract_shipments/${ids}`, responseData, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.success === true) {
          setIsSubmitted(false);
          document.getElementById("messageContainer").innerHTML = `
              <div class="success-message">
                  ${response.data.msg}
              </div>
          `;

          window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: animated scrolling
          });

          fetchData();

          setData(response);
          setIsEditMode(false);
        } else if (response.data.success === false) {
          setIsSubmitted(false);
          document.getElementById("messageContainer").innerHTML = `
              <div class="error-message">
                  ${response.data.msg}
              </div>
          `;
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: animated scrolling
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const createProduct = () => {
    if (!datas1.laycan_from_date || !datas1.laycan_to_date) {
      alert("Please fill Laycan From Date & Laycan To Date");
    } else {
      navigate("/contract/add/" + ids, {
        state: {
          contractshipment: datas[0].contract_ship_code,
          buyercontract: datas[0].contract,
        },
      });
    }
  };
  const reloading = () => {
    window.location.reload();
  };
  console.log(datas1.laycan_from_date);
  const createOrder = () => {
    if (!datas1.laycan_from_date || !datas1.laycan_to_date) {
      alert("Please fill Laycan From Date & Laycan To Date");
    } else {
      navigate("/contract-shipment/new-order/" + ids, {
        state: {
          laycanDate: laycanDates,
          laycanToDate: laycanToDates,
        },
      });
    }
  };

  const createTcf = () => {
    if (!datas1.laycan_from_date || !datas1.laycan_to_date) {
      alert("Please fill Laycan From Date & Laycan To Date");
    } else {
      fetch(`${API_URL}add/contract_ship/tcf/${ids}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((response) => {
          console.log(response);
          fetchData();
          alert("TCF Created Successfully");
          // Check the HTTP status code
          if (response.status === 200) {
            fetchData();

            navigate(`/shipment/contract-shipment-details/${ids}`);
            console.log("Success:", response.status);
            toast.success("TCF Created Successfully", {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            console.log("Unexpected status code:", response.status);
            // Handle unexpected status code
          }
        })
        .catch((error) => {
          console.error(error);
          // Handle the error, you can show a toast or any other error handling logic here
        });
    }
  };

  if (datas1.record_type === "Buyer - Long Term") {
    return (
      <div className="priceAccount  full-container" id="view-contract-ship">
        <Header />
        <div>
          <div className="row">
            <div className="col-md-2 recent-item">
              <RecentItems />
            </div>
            <div className="col-md-10 right-section">
              <div className="page-header" id="">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Contract Shipment{" "}
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard / shipment / Contract Shipment
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
            <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
           </button> */}
                  </div>
                </div>
              </div>

              {isEditMode ? (
                <>
                  <div className="tasks cons-shipment" id="view-page">
                    <div id="messageContainer"></div>
                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                    <div className="row">
                      <div className="col-md-12" id="head">
                        <h4 className="heading">Contract Shipment Details</h4>

                        <hr></hr>
                      </div>
                    </div>

                    <Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency"
                            placeholder="Currency"
                            defaultValue={datas1.currency || ""}
                            tabIndex={1}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            tabIndex={4}
                            className="record-type"
                            defaultValue={datas1.record_type}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            tabIndex={2}
                            name="exchange_rate"
                            defaultValue={datas1.exchange_rate || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            tabIndex={5}
                            placeholder="Contract"
                            name="contract"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {companies.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                            tabIndex={3}
                            value={
                              datas1.contract_shipment_status || "Completed"
                            }
                            onChange={handleChangeInput}
                          >
                            <option value="draft" selected>
                              Draft
                            </option>
                            <option value="Activated">Activated</option>
                            <option value="Completed" selected>
                              Completed
                            </option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                            <option value="Partially Completed">
                              Partially Completed
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <input
                          type="checkbox"
                          name="locked"
                          tabIndex={6}
                          checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                          onChange={handleCheckboxLocked} // Handle the change event
                          className="mx-3"
                        />
                        <label>Locked</label>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Price</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            defaultValue={datas1.shipping_term || ""}
                            tabIndex={7}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="FOB-B">FOB-B</option>
                            <option value="FOB-MV">FOB-MV</option>
                            <option value="CFR-MV">CFR-MV</option>
                            <option value="CIF-MV">CIF-MV</option>
                            <option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            id="price"
                            tabIndex={10}
                            name="price"
                            disabled={
                              datas1.price_type !== "GcNewc" &&
                              datas1.price_type !== "Fix Price"
                            }
                            defaultValue={datas1.price || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_type"
                            tabIndex={8}
                            placeholder="Price type"
                            defaultValue={datas1.price_type || ""}
                            onChange={(e) => {
                              handleChangeInput(e);
                              if (
                                e.target.value === "GcNewc" ||
                                e.target.value === "Fix Price"
                              ) {
                                // If price_type is "Fix Price", disable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = true;
                                document.getElementById(
                                  "sales_price_margin"
                                ).value = "";
                              } else if (
                                e.target.value !== "GcNewc" ||
                                e.target.value !== "Fix Price"
                              ) {
                                document.getElementById(
                                  "price"
                                ).disabled = true;
                                document.getElementById("price").value = "";
                              } else {
                                // Otherwise, enable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = false;
                              }
                            }}
                          >
                            <option value="">Select</option>
                            {getPrice.map((x) => {
                              return (
                                <option value={x.price_type_name}>
                                  {x.price_type_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            tabIndex={11}
                            name="price_basis"
                            placeholder="Price Basis"
                            defaultValue={datas1.price_basis || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      {datas1.price_type === "Fix Price" ? (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              tabIndex={9}
                              name="sales_price_margin"
                              id="sales_price_margin"
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              tabIndex={9}
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            tabIndex={12}
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            defaultValue={datas1.price_pmt_in_words || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Delivery</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_from"
                            tabIndex={13}
                            placeholder="Laycan Year From"
                            // className="error-validation"
                            defaultValue={datas1.laycan_year_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>

                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        {" "}
                      </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_to"
                            placeholder="Laycan Year To"
                            tabIndex={14}
                            // className="error-validation"
                            defaultValue={datas1.laycan_year_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        {" "}
                      </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_from"
                            tabIndex={15}
                            placeholder="Laycan Month From"
                            // className="error-validation"
                            defaultValue={datas1.laycan_month_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_to"
                            tabIndex={16}
                            placeholder="Laycan Month To"
                            // className="error-validation"
                            defaultValue={datas1.laycan_month_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                    <h4 className="heading">Quanitity</h4>
                    <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Quantity Type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Quantity Type"
                            tabIndex={17}
                            defaultValue={datas1.quantity_type || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of Shipments"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Shipments"
                            name="no_of_shipments"
                            tabIndex={21}
                            defaultValue={datas1.no_of_shipments || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity"
                            tabIndex={18}
                            defaultValue={datas1.quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      

                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Child Shipment Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Child Shipment Count"
                            name="child_shipment_count"
                            tabIndex={22}
                            defaultValue={datas1.child_shipment_count || contractShipment.length}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>When we receive money from Our Bank</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity From"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Quantity From"
                            name="quantity_from"
                            tabIndex={19}
                            defaultValue={datas1.quantity_from || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            className="error-validation"
                            name="shipment_quantity_tolerance"
                            tabIndex={20}
                            defaultValue={
                              datas1.shipment_quantity_tolerance || ""
                            }
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity To"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Quantity To"
                            name="quantity_to"
                            tabIndex={23}
                            defaultValue={datas1.quantity_to || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                        <Form.Group as={Col} md="6">
                        {" "}
                      </Form.Group>
                     
                    </Row>
                    <Row>
                      <h4 className="heading">System Information</h4>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            tabIndex={24}
                            name="tcf_count"
                            defaultValue={datas1.tcf_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of orders"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={26}
                            placeholder="No of orders"
                            name="no_of_orders"
                            defaultValue={datas1.no_of_orders || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>No of Order against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Product Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={25}
                            placeholder="Product Count"
                            name="product_count"
                            defaultValue={datas1.product_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>No of Product thru Availability check</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={27}
                            placeholder="Order Quantity"
                            name="order_quantity"
                            defaultValue={datas1.order_quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <p></p>
                    <p></p>
                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <div className="tasks cons-shipment" id="view-page">
                  {datas.length > 0 ? (
                    datas.map((x) => (
                      <>
                        {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}
                        <p className="edit-btn">
                          <button id="edits" onClick={handleEditClick}>
                            Edit
                          </button>
                          <Link to={`/contract-shipment/create-view-list`}>
                            {" "}
                            <button id="edits">Cancel</button>
                          </Link>
                          {isChildPresent ? (
                            <>
                              {" "}
                              <button id="edits" onClick={createProduct}>
                                Create Product
                              </button>
                              <Link>
                                <button id="edits" onClick={createTcf}>
                                  Create TCF
                                </button>
                              </Link>
                              <button id="edits" onClick={createOrder}>
                                Create Order
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-primary"
                              id="edits"
                              onClick={noOfshipment}
                            >
                              Contract Shipment Details
                            </button>
                          )}
                        </p>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                Contract Shipment Details
                              </h4>

                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>contract Shipment No</span>{" "}
                                </td>
                                <td>{x.contract_ship_code}</td>
                                <td id="td-right">
                                  <span>Record Type</span>
                                </td>
                                <td>{x.record_type}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>
                                </td>
                                <td>{x.currency}</td>
                                <td id="td-right">
                                  <span>
                                    Contract{" "}
                                    {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                                  </span>
                                </td>
                                <td>
                                  <Link
                                    to={`/contract/contract-details/${x.contract_id}`}
                                  >
                                    {" "}
                                    {x.contract}{" "}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Exchange Rate</span>
                                </td>
                                <td>{x.exchange_rate}</td>
                                <td id="td-right">
                                  <span>Locked</span>
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    name="locked"
                                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                    onChange={handleCheckboxLocked} // Handle the change event
                                    className="mx-3"
                                    disabled={isButtonEnabled}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Contract Shipment Status</span>
                                </td>
                                <td>
                                  {x.contract_shipment_status || "Completed"}
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Price</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Shipping Term</span>{" "}
                                </td>
                                <td>{x.shipping_term}</td>
                                <td id="td-right">
                                  <span>pricing</span>
                                </td>
                                <td>{x.pricing}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price type</span>
                                </td>
                                <td>{x.price_type}</td>
                                <td id="td-right">
                                  <span>Price</span>
                                </td>
                                <td>{renderShipmentQty(x.price)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>
                                    price basis{" "}
                                    {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                                  </span>
                                </td>
                                <td>{x.price_basis}</td>
                                <td id="td-right">
                                  <span>sales price margin</span>
                                </td>
                                <td>{x.sales_price_margin}</td>
                              </tr>
                              <tr>
                                <td id="td-right"></td>
                                <td></td>
                                <td id="td-right">
                                  <span>price PMT words</span>
                                </td>
                                <td>{x.price_pmt_in_words}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Delivery</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan Year From</span>{" "}
                                </td>
                                <td>{x.laycan_year_from}</td>
                                <td id="td-right">
                                  <span>Laycan</span>
                                </td>
                                <td>{x.laycan}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year to</span>
                                </td>
                                <td>{x.laycan_year_to}</td>
                                <td id="td-right">
                                  {/* <span>laycan from date</span> */}
                                </td>
                                <td>{/* {x.laycan_from_date} */}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month from</span>
                                </td>
                                <td>{x.laycan_month_from}</td>

                                <td id="td-right">
                                  {/* <span>laycan to date</span> */}
                                </td>
                                <td>{/* {x.laycan_to_date} */}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month to</span>
                                </td>
                                <td>{x.laycan_month_to}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Quanitity</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                             <tr>
                             <td id="td-right">
                                  <span>Quantity Info	
                                  </span>{" "}
                                </td>
                                <td>{x.quantity_info}</td>
                                <td id="td-right">
                                  <span>No of Shipments	

                                  </span>{" "}
                                </td>
                                <td>{x.no_of_shipments}</td>
                              </tr>
                              <tr>
                             <td id="td-right">
                                  <span>Quantity type	
                                  </span>{" "}
                                </td>
                                <td>{x.quantity_type}</td>
                                <td id="td-right">
                                  <span>Child Shipment Count	

                                  </span>{" "}
                                </td>
                                <td>{x.child_shipment_count||contractShipment.length}</td>
                              </tr>
                              <tr>
                              <td id="td-right">
                                  <span>Quantity	

                                  </span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity)}</td>
                                <td id="td-right">
                                  <span>Shipment Quantity Tolerance	
	

                                  </span>{" "}
                                </td>
                                <td>{x.shipment_quantity_tolerance}</td>
                              </tr>
                              <tr>
                             <td id="td-right">
                                  <span>Quantity from	
                                  </span>{" "}
                                </td>
                                <td>{x.quantity_from}</td>
                                <td id="td-right">
                                  <span> </span>{" "}
                                </td>
                                <td></td>
                                </tr>

                                <tr>
                                <td id="td-right">
                                  <span>Quantity	 to

                                  </span>{" "}
                                </td>
                                <td>{x.quantity_to}</td>
                              </tr>
                             
                            </tbody>
                          </table>
                        </div>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">System Information</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                <span className="a-a">
                                         TCF Count{" "}
                                                <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{renderShipmentQty(x.tcf_count)}</td>
                                <td id="td-right">
                                   <span className="a-a">
                                        No of Orders{" "}
                                                <Tooltip text={<span>No of Order against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{x.no_of_orders}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                <span className="a-a">
                                         Product Count{" "}
                                                <Tooltip text={<span>No of Product thru Availability check</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{x.product_count}</td>
                                <td id="td-right">
                                  <span>
                                    order quantity
                                    {/* <OverlayTrigger
                                   placement="right"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={renderTooltip}
                                 >
                                   <Button variant="success" className="tooltips">
                                     <IoIosHelpCircle id="help" />
                                   </Button>
                                 </OverlayTrigger>{" "} */}
                                  </span>
                                </td>
                                <td>{renderShipmentQty(x.order_quantity)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Created By </span>{" "}
                                </td>
                                <td>{x.created_by}</td>
                                <td id="td-right">
                                  <span>Last Modified By</span>{" "}
                                </td>
                                <td>{x.last_modified_by}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>Shipment Month </span>{" "}
                                </td>
                                <td>{x.shipment_month}</td>
                                <td id="td-right">
                                <span className="a-a">
                                        Shipment Qty{" "}
                                                <Tooltip text={<span>IF (Order_Qty__c =0, Quantity__c , Order_Qty__c )</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{x.shipment_quantity}</td>
                              </tr>
                            </tbody>
                          </table>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-ship" className="">
                              <thead>
                                <tr>
                                  <th>contract ship code</th>
                                  <th>Contract</th>
                                  <th>Quantity</th>
                                  <th>Laycan</th>
                                  <th>Product Count </th>
                                  <th>TCF Count </th>
                                  <th>No of Orders </th>
                                  <th>Pricing</th>
                                  <th>Order Qty</th>
                                </tr>
                              </thead>

                              {contractShipmentLength > 0 ? (
                                <tbody>
                                  {contractShipment.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/contract-shipment/detail/${y.id}`}
                                        >
                                          {y.contract_ship_code}
                                        </Link>
                                      </td>
                                      <td>{y.contract}</td>
                                      <td>{y.quantity}</td>
                                      <td>{y.laycan}</td>
                                      <td>{y.product_count}</td>
                                      <td>{y.tcf_count}</td>
                                      <td>{y.no_of_orders}</td>
                                      <td>{y.pricing}</td>
                                      <td>{y.order_qty}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Products</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Products
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Products</span>
                              </h4>
                              <div id="inner-heading"></div>
                              <hr></hr>
                            </div>
                            <table id="product" className="">
                              <thead>
                                <tr>
                                  <th>Product No</th>
                                  <th>Product Name</th>
                                  <th>Product Code</th>
                                  <th>Quantity MT</th>
                                  <th>Status</th>
                                  <th>Buyer Name </th>
                                  <th>Laycan From Date </th>
                                  <th>Laycan To Date</th>
                                </tr>
                              </thead>

                              {product.length > 0 ? (
                                <tbody>
                                  {product.map((x, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/product/product-detail/${x.id}`}
                                        >
                                          {" "}
                                          {x.product_no}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/productmaster/product-detail/${x.product_master_id}`}
                                        >
                                          {x.product_name}
                                        </Link>
                                      </td>

                                      <td>
                                        <Link
                                          to={`/productmaster/product-detail/${x.product_master_id}`}
                                        >
                                          {x.product_code}
                                        </Link>
                                      </td>

                                      <td>{x.quantity_MT}</td>
                                      <td>{x.status}</td>
                                      <td>{x.buyer_name}</td>
                                      <td>{x.laycan_from_date}</td>
                                      <td>{x.laycan_to_date}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Trade Confirmation Form</span>
                              </h4>
                              <div id="inner-heading"></div>
                              <hr></hr>
                            </div>
                            <table id="tcf" className="">
                              <thead>
                                <tr>
                                  <th>TCF No</th>
                                  <th>Buyer</th>
                                  <th>Supplier</th>
                                  <th>Quantity (MT) </th>
                                  <th>Contractual GAR (P) </th>
                                  <th>Actual GAR (P) </th>
                                  <th>Actual GAR (S) </th>
                                  <th>Proposed Laycan From </th>
                                  <th>Proposed Laycan To</th>
                                </tr>
                              </thead>

                              {tcfList.length > 0 ? (
                                <tbody>
                                  {tcfList.map((x, i) => (
                                    <tr>
                                      <td>
                                        <Link to={"/tcf/tcf-details/" + x.id}>
                                          {x.tcf_no}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.account_id}`}>
                                          {x.buyer}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.supplier_id}`}>
                                          {x.supplier}
                                        </Link>
                                      </td>
                                      <td>{x.quantity_MT}</td>
                                      <td>{x.contractual_GAR_P}</td>
                                      <td>{x.actual_gar_p}</td>
                                      <td>{x.actual_gar_s}</td>
                                      <td>{x.proposed_laycan_from}</td>
                                      <td>{x.proposed_laycan_to}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Orders </span>
                              </h4>
                              <div id="inner-heading"></div>
                              <hr></hr>
                            </div>
                            <table id="orders" className="">
                              <thead>
                                <tr>
                                  <th>Order Number </th>
                                  <th>Status</th>
                                  <th>Account Name </th>
                                  <th>Supplier</th>
                                  <th>Vessel Name </th>
                                  <th>Ship Month </th>
                                  <th>Shipment Qty </th>
                                  <th>Laycan From Date </th>
                                  <th>Supplier Invoice Price </th>
                                  <th>Commercial Invoice Price</th>
                                </tr>
                              </thead>

                              {orders.length > 0 ? (
                                <tbody>
                                  {orders.map((x, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/order/view-order-details/${x.id}`}
                                        >
                                          {x.order_code_no}
                                        </Link>
                                      </td>
                                      <td>{x.status}</td>
                                      <td>
                                        <Link to={`/accounts/${x.account_id}`}>
                                          {x.account_name}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.supplier_id}`}>
                                          {x.supplier}
                                        </Link>
                                      </td>
                                      <td>{x.vessel_name}</td>
                                      <td>{x.shipment_month}</td>
                                      <td>{x.shipment_qty}</td>
                                      <td>{x.laycan_from_date}</td>
                                      <td>{x.supplier_invoice}</td>
                                      <td>{x.commercial_invoice}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Notes & Attachments</span>
                                <span>
                                  <i className=""></i>
                                  New Note
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Open Activities</span>
                                <span>
                                  <i className=""></i>
                                  New Task
                                </span>
                                <span>
                                  <i className=""></i>
                                  New Event
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Activity History</span>
                                <span>
                                  <i className=""></i>
                                  Log a Call
                                </span>
                                <span>
                                  <i className=""></i>
                                  Mail Merge
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (datas1.record_type === "Supplier - Long Term") {
    return (
      <div className="priceAccount full-container" id="view-contract-ship">
        <Header />
        <div>
          <div className="row">
            <div className="col-md-2 recent-item">
              <RecentItems />
            </div>
            <div className="col-md-10 right-section">
              <div className="page-header" id="">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Contract Shipment
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard / shipment / Contract Shipment
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
           <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
          </button> */}
                  </div>
                </div>
              </div>

              {isEditMode ? (
                <>
                  <div className="tasks cons-shipment" id="view-page">
                    <div id="messageContainer"></div>
                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                    <div className="row">
                      <div className="col-md-12" id="head">
                        <h4 className="heading">Contract Shipment Details</h4>

                        <hr></hr>
                      </div>
                    </div>

                    <Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency"
                            defaultValue={datas1.currency || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            className="record-type"
                            defaultValue={datas1.record_type || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            name="exchange_rate"
                            defaultValue={datas1.exchange_rate || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6"></Form.Group>

                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_type"
                            placeholder="Price type"
                            defaultValue={datas1.price_type || ""}
                            onChange={(e) => {
                              handleChangeInput(e);
                              if (
                                e.target.value === "GcNewc" ||
                                e.target.value === "Fix Price"
                              ) {
                                // If price_type is "Fix Price", disable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = true;
                                document.getElementById(
                                  "sales_price_margin"
                                ).value = "";
                              } else if (
                                e.target.value !== "GcNewc" ||
                                e.target.value !== "Fix Price"
                              ) {
                                document.getElementById(
                                  "price"
                                ).disabled = true;
                                document.getElementById("price").value = "";
                              } else {
                                // Otherwise, enable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = false;
                              }
                            }}
                          >
                            <option value="">Select</option>
                            {getPrice.map((x) => {
                              return (
                                <option value={x.price_type_name}>
                                  {x.price_type_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity"
                            defaultValue={datas1.quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity From"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity From"
                            name="quantity_from"
                            defaultValue={datas1.quantity_from || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity To"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity To"
                            name="quantity_to"
                            defaultValue={datas1.quantity_to || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Quantity Type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Quantity Type"
                            defaultValue={datas1.quantity_type || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            id="price"
                            name="price"
                            disabled={
                              datas1.price_type !== "GcNewc" &&
                              datas1.price_type !== "Fix Price"
                            }
                            defaultValue={datas1.price || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            defaultValue={datas1.price_pmt_in_words || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group> 
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            name="shipment_quantity_tolerance"
                            defaultValue={
                              datas1.shipment_quantity_tolerance || ""
                            }
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_from"
                            placeholder="Laycan Year From"
                            defaultValue={datas1.laycan_year_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_to"
                            placeholder="Laycan Year To"
                            defaultValue={datas1.laycan_year_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_from"
                            placeholder="Laycan Month From"
                            defaultValue={datas1.laycan_month_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_to"
                            placeholder="Laycan Month To"
                            defaultValue={datas1.laycan_month_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of Shipments"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Shipments"
                            name="no_of_shipments"
                            defaultValue={datas1.laycan_month_to || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            className="error-validation"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {companies.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6"></Form.Group>
                      {datas1.price_type === "Fix Price" ? (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}

                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                            value={
                              datas1.contract_shipment_status || "Completed"
                            }
                            onChange={handleChangeInput}
                          >
                            <option value="draft" selected>
                              Draft
                            </option>
                            <option value="Activated">Activated</option>
                            <option value="Completed" selected>
                              Completed
                            </option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                            <option value="Partially Completed">
                              Partially Completed
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Order quantity"
                            name="order_quantity"
                            defaultValue={datas1.order_quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"
                            defaultValue={datas1.price_basis || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            defaultValue={datas1.shipping_term || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="FOB-B">FOB-B</option>
                            <option value="FOB-MV">FOB-MV</option>
                            <option value="CFR-MV">CFR-MV</option>
                            <option value="CIF-MV">CIF-MV</option>
                            <option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year"
                            placeholder="Laycan Year"
                            defaultValue={datas1.laycan_year || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            name="tcf_count"
                            defaultValue={datas1.tcf_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                    </Row>

                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <div className="tasks cons-shipment" id="view-page">
                  {datas.length > 0 ? (
                    datas.map((x) => (
                      <>
                        {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}
                        <p className="edit-btn">
                          <button id="edits" onClick={handleEditClick}>
                            Edit
                          </button>
                          <Link to={`/contract-shipment/create-view-list`}>
                            {" "}
                            <button id="edits">Cancel</button>
                          </Link>
                          {isChildPresent ? (
                            <>
                              {" "}
                              <button id="edits" onClick={createProduct}>
                                Create Product
                              </button>
                              <Link>
                                <button id="edits" onClick={createTcf}>
                                  Create TCF
                                </button>
                              </Link>
                              <button id="edits" onClick={createOrder}>
                                Create Order
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn btn-primary"
                              id="edits"
                              onClick={noOfshipment}
                            >
                              Contract Shipment Details
                            </button>
                          )}
                        </p>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                Contract Shipment Details
                              </h4>

                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>contract ship code</span>{" "}
                                </td>
                                <td>{x.contract_ship_code}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>{" "}
                                </td>
                                <td>{x.currency}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>record type</span>{" "}
                                </td>
                                <td>{x.record_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>exchange rate</span>{" "}
                                </td>
                                <td>{x.exchange_rate}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              {/* <tr>
                                <td id="td-right">
                                  <span>Laycan From Date</span>{" "}
                                </td>
                                <td>{x.laycan_from_date}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan To Date</span>{" "}
                                </td>
                                <td>{x.laycan_to_date}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>*/}
                              <tr>
                                <td id="td-right">
                                  <span>price type</span>{" "}
                                </td>
                                <td>{x.price_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity from</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity_from)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity to</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity_to)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity type</span>{" "}
                                </td>
                                <td>{x.quantity_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.price)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price pmt in words</span>{" "}
                                </td>
                                <td>{x.price_pmt_in_words}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>shipment quantity tolerance</span>{" "}
                                </td>
                                <td>
                                  {renderShipmentQty(
                                    x.shipment_quantity_tolerance
                                  )}
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year from</span>{" "}
                                </td>
                                <td>{x.laycan_year_from}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year to</span>{" "}
                                </td>
                                <td>{x.laycan_year_to}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month from</span>{" "}
                                </td>
                                <td>{x.laycan_month_from}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month to</span>{" "}
                                </td>
                                <td>{x.laycan_month_to}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>no of shipments</span>{" "}
                                </td>
                                <td>{x.no_of_shipments}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>contract</span>{" "}
                                </td>
                                <td>
                                  <Link
                                    to={`/contract/contract-details/${x.contract_id}`}
                                  >
                                    {" "}
                                    {x.contract}{" "}
                                  </Link>
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan</span>{" "}
                                </td>
                                <td> {x.laycan} </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>sales price margin</span>{" "}
                                </td>
                                <td>{x.sales_price_margin}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>contract shipment status</span>{" "}
                                </td>
                                <td>
                                  {x.contract_shipment_status || "Completed"}
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>order quantity</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.order_quantity)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>quantity info</span>{" "}
                                </td>
                                <td>{x.quantity_info}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price basis</span>{" "}
                                </td>
                                <td>{x.price_basis}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>shipping term</span>{" "}
                                </td>
                                <td>{x.shipping_term}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year</span>{" "}
                                </td>
                                <td>{x.laycan_year}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Pricing</span>{" "}
                                </td>
                                <td>{x.pricing}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                <span className="a-a">
                                         TCF Count{" "}
                                                <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{renderShipmentQty(x.tcf_count)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>Created By </span>{" "}
                                </td>
                                <td>{x.created_by}</td>
                                <td id="td-right"></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  {/* <span>Created By	</span>{" "} */}
                                </td>
                                <td></td>
                                <td id="td-right">
                                  <span>Last Modified By</span>{" "}
                                </td>
                                <td>{x.last_modified_by}</td>
                              </tr>
                            </tbody>
                          </table>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Open Activities</span>
                                <span>
                                  <i className=""></i>
                                  New Task
                                </span>
                                <span>
                                  <i className=""></i>
                                  New Event
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          {/* <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment Information</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-ship" className="">
                              <thead>
                                <tr>
                                  <th>contract ship code</th>
                                  <th>parent shipment</th>
                                  <th>price</th>
                                  <th>price basis</th>
                                  <th>quantity</th>
                                </tr>
                              </thead>

                              {contractShipmentLength > 0 ? (
                                <tbody>
                                  {contractShipment.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/contract-shipment/detail/${y.id}`}
                                        >
                                          {y.contract_ship_code}
                                        </Link>
                                      </td>
                                      <td>{y.parent_shipment}</td>
                                      <td>{y.price}</td>
                                      <td>{y.price_basis}</td>
                                      <td>{y.quantity}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row> */}
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Activity History</span>
                                <span>
                                  <i className=""></i>
                                  Log a Call
                                </span>
                                <span>
                                  <i className=""></i>
                                  Mail Merge
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Notes & Attachments</span>
                                <span>
                                  <i className=""></i>
                                  New Note
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Products</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Products
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Trade Confirmation Form</span>
                              </h4>
                              <div id="inner-heading"></div>
                              <hr></hr>
                            </div>
                            <table id="tcf" className="">
                              <thead>
                                <tr>
                                  <th>TCF No</th>
                                  <th>Buyer</th>
                                  <th>Supplier</th>
                                  <th>Quantity (MT) </th>
                                  <th>Contractual GAR (P) </th>
                                  <th>Actual GAR (P) </th>
                                  <th>Actual GAR (S) </th>
                                  <th>Proposed Laycan From </th>
                                  <th>Proposed Laycan To</th>
                                </tr>
                              </thead>

                              {tcfList.length > 0 ? (
                                <tbody>
                                  {tcfList.map((x, i) => (
                                    <tr>
                                      <td>
                                        <Link to={"/tcf/tcf-details/" + x.id}>
                                          {x.tcf_no}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.account_id}`}>
                                          {x.buyer}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.supplier_id}`}>
                                          {x.supplier}
                                        </Link>
                                      </td>
                                      <td>{x.quantity_MT}</td>
                                      <td>{x.contractual_GAR_P}</td>
                                      <td>{x.actual_gar_p}</td>
                                      <td>{x.actual_gar_s}</td>
                                      <td>{x.proposed_laycan_from}</td>
                                      <td>{x.proposed_laycan_to}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipments</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Shipment
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-ship" className="">
                              <thead>
                                <tr>
                                  <th>contract ship code</th>
                                  <th>Contract</th>
                                  <th>Quantity</th>
                                  <th>Laycan</th>
                                  <th>Product Count </th>
                                  <th>TCF Count </th>
                                  <th>No of Orders </th>
                                  <th>Pricing</th>
                                  <th>Order Qty</th>
                                </tr>
                              </thead>

                              {contractShipmentLength > 0 ? (
                                <tbody>
                                  {contractShipment.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/contract-shipment/detail/${y.id}`}
                                        >
                                          {y.contract_ship_code}
                                        </Link>
                                      </td>
                                      <td>{y.contract}</td>
                                      <td>{y.quantity}</td>
                                      <td>{y.laycan}</td>
                                      <td>{y.product_count}</td>
                                      <td>{y.tcf_count}</td>
                                      <td>{y.no_of_orders}</td>
                                      <td>{y.pricing}</td>
                                      <td>{y.order_qty}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td> <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>History Information</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="historys" className="">
                              <thead>
                                <tr>
                                  <th>Field Name</th>
                                  <th>New Value</th>
                                  <th>Old Value</th>
                                  <th>Changed By</th>
                                  <th>Change Time</th>
                                </tr>
                              </thead>

                              {history.length > 0 ? (
                                <tbody>
                                  {history.map((y, i) => (
                                    <tr>
                                      <td>{y.field_name}</td>
                                      <td>{y.new_value}</td>
                                      <td>{y.old_value}</td>
                                      <td>{y.changed_by}</td>
                                      <td>{y.change_time}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  if (datas1.record_type === "Buyer - Spot") {
    return (
      <div className="priceAccount  full-container" id="view-contract-ship">
        <Header />
        <div>
          <div className="row">
            <div className="col-md-2 recent-item">
              <RecentItems />
            </div>
            <div className="col-md-10 right-section">
              <div className="page-header" id="">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Contract Shipment
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard / shipment / Contract Shipment
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
           <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
          </button> */}
                  </div>
                </div>
              </div>

              {isEditMode ? (
                <>
                 <div className="tasks cons-shipment" id="view-page">
                    <div id="messageContainer"></div>
                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                    <div className="row">
                      <div className="col-md-12" id="head">
                        <h4 className="heading">Contract Shipment Details</h4>

                        <hr></hr>
                      </div>
                    </div>

                    <Row className="mb-3">
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency"
                            tabIndex={1}
                            defaultValue={datas1.currency || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            tabIndex={5}
                            className="record-type"
                            defaultValue={datas1.record_type || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            tabIndex={2}
                            name="exchange_rate"
                            defaultValue={datas1.exchange_rate || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {companies.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                            tabIndex={6}
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            tabIndex={3}
                            placeholder="Contract shipment status"
                            defaultValue={
                              datas1.contract_shipment_status
                                ? datas1.contract_shipment_status
                                : "Completed"
                            }
                            onChange={handleChangeInput}
                          >
                            {" "}
                            <option value="draft" selected>
                              Draft
                            </option>
                            <option value="Activated">Activated</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                            <option value="Partially Completed">
                              Partially Completed
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            tabIndex={7}
                            placeholder="Order Quantity"
                            name="order_quantity"
                            defaultValue={datas1.order_quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Parent Shipment"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            tabIndex={4}
                            placeholder="Parent Shipment"
                            name="parent_shipment"
                            defaultValue={datas1.parent_shipment || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <input
                          type="checkbox"
                          name="locked"
                          tabIndex={8}
                          checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                          onChange={handleCheckboxLocked} // Handle the change event
                          className="mx-3"
                        />
                        <label>Locked</label>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Price</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_type"
                            tabIndex={9}
                            placeholder="Price type"
                            defaultValue={datas1.price_type || ""}
                            onChange={(e) => {
                              handleChangeInput(e);
                              if (
                                e.target.value === "GcNewc" ||
                                e.target.value === "Fix Price"
                              ) {
                                // If price_type is "Fix Price", disable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = true;
                                document.getElementById(
                                  "sales_price_margin"
                                ).value = "";
                              } else if (
                                e.target.value !== "GcNewc" ||
                                e.target.value !== "Fix Price"
                              ) {
                                document.getElementById(
                                  "price"
                                ).disabled = true;
                                document.getElementById("price").value = "";
                              } else {
                                // Otherwise, enable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = false;
                              }
                            }}
                          >
                            <option value="">Select</option>
                            {getPrice.map((x) => {
                              return (
                                <option value={x.price_type_name}>
                                  {x.price_type_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            tabIndex={13}
                            placeholder="Price Basis"
                            defaultValue={datas1.price_basis || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            id="price"
                            name="price"
                            tabIndex={10}
                            disabled={
                              datas1.price_type !== "GcNewc" &&
                              datas1.price_type !== "Fix Price"
                            }
                            defaultValue={datas1.price || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            tabIndex={14}
                            placeholder="Shipping Term"
                            defaultValue={datas1.shipping_term || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="FOB-B">FOB-B</option>
                            <option value="FOB-MV">FOB-MV</option>
                            <option value="CFR-MV">CFR-MV</option>
                            <option value="CIF-MV">CIF-MV</option>
                            <option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            tabIndex={11}
                            defaultValue={datas1.price_pmt_in_words || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      {datas1.price_type === "Fix Price" ? (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              tabIndex={12}
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              tabIndex={12}
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}
                    </Row>
                    <Row>
                      <h4 className="heading">Delivery</h4>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            tabIndex={15}
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                          tabIndex={16}
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                        {endDateError && (
                          <div
                            className="error-validations"
                            style={{ color: "red" }}
                          >
                            {endDateError}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year"
                            placeholder="Laycan Year"
                            tabIndex={17}
                            // className="error-validation"
                            defaultValue={datas1.laycan_year || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month"
                            tabIndex={18}
                            placeholder="Laycan Month"
                            // className="error-validation"
                            defaultValue={datas1.laycan_month || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">Quanitity</h4>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            tabIndex={19}
                            placeholder="Quantity"
                            name="quantity"
                            defaultValue={datas1.quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Base Quantity Type"
                        >
                          <Form.Select
                          tabIndex={21}
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Base Quantity"
                            defaultValue={datas1.quantity_type || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={20}
                            placeholder="Shipment Quantity Tolerance"
                            className="error-validation"
                            name="shipment_quantity_tolerance"
                            defaultValue={
                              datas1.shipment_quantity_tolerance || ""
                            }
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                    <Row>
                      <h4 className="heading">System Information</h4>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={22}
                            placeholder="TCF Count"
                            name="tcf_count"
                            defaultValue={datas1.tcf_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of orders"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            tabIndex={24}
                            placeholder="No of orders"
                            name="no_of_orders"
                            defaultValue={datas1.no_of_orders || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>No of Order against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Product Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Product Count"
                            name="product_count"
                            tabIndex={23}
                            defaultValue={datas1.product_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>No of Product thru Availability check</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                    </Row>

                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <div className="tasks cons-shipment" id="view-page">
                  {datas.length > 0 ? (
                    datas.map((x) => (
                      <>
                        {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}

                        <p className="edit-btn btn-disabled">
                          <button id="edits" onClick={handleEditClick}>
                            Edit
                          </button>
                          <Link to={`/contract-shipment/create-view-list`}>
                            {" "}
                            <button id="edits">Cancel</button>
                          </Link>
                          {!datas1.laycan_from_date ||
                          !datas1.laycan_to_date ? (
                            <span>
                              <button
                                id="edits"
                                className="tcf-hide"
                                onClick={createProduct}
                              >
                                Create Product
                              </button>
                              <Link>
                                <button
                                  id="edits"
                                  className="tcf-hide"
                                  onClick={createTcf}
                                >
                                  Create TCF
                                </button>
                              </Link>
                              <button
                                id="edits"
                                className="tcf-hide"
                                onClick={createOrder}
                              >
                                Create Order
                              </button>
                            </span>
                          ) : (
                            <>
                              <button id="edits" onClick={createProduct}>
                                Create Product
                              </button>
                              <Link>
                                <button id="edits" onClick={createTcf}>
                                  Create TCF
                                </button>
                              </Link>
                              <button id="edits" onClick={createOrder}>
                                Create Order
                              </button>
                            </>
                          )}
                          {/* <button className='btn btn-primary' id='edits' onClick={noOfshipment}>Contract Shipment Details</button>  */}
                        </p>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                Contract Shipment Details
                              </h4>

                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>contract Shipment No</span>{" "}
                                </td>
                                <td>{x.contract_ship_code}</td>

                                <td id="td-right">
                                  <span>Record Type</span>
                                </td>
                                <td>{x.record_type}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>
                                </td>
                                <td>{x.currency}</td>
                                <td id="td-right">
                                  <span>Contract </span>
                                </td>
                                <td>
                                  <Link
                                    to={`/contract/contract-details/${x.contract_id}`}
                                  >
                                    {" "}
                                    {x.contract}{" "}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Exchange Rate</span>
                                </td>
                                <td>{x.exchange_rate}</td>
                                <td id="td-right">
                                  <span>Order Quantity</span>
                                </td>
                                <td>{renderShipmentQty(x.order_quantity)}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Contract Shipment Status</span>
                                </td>
                                <td>
                                  {x.contract_shipment_status || "Completed"}
                                </td>

                                <td id="td-right">
                                  <span>Locked</span>
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="checkbox"
                                    name="locked"
                                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                    onChange={handleCheckboxLocked} // Handle the change event
                                    className="mx-3"
                                    disabled={isButtonEnabled}
                                  />
                                </td>
                              </tr>
                              <tr key={x.parentship_id}>
                                <td id="td-right">
                                  <span>Parent Shipment</span>
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/shipment/contract-shipment-details/${x.parentship_id}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleReload(x.parentship_id);
                                    }}
                                  >
                                    {x.parent_shipment}
                                  </Link>
                                </td>

                                <td id="td-right">
                                  {/* <span>Locked</span> */}
                                </td>
                                <td>{/* {x.locked} */}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Price</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>price type</span>
                                </td>
                                <td>{x.price_type}</td>
                                <td id="td-right">
                                  <span>pricing</span>
                                </td>
                                <td>{x.pricing}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Price</span>
                                </td>
                                <td>{renderShipmentQty(x.price)}</td>
                                <td id="td-right">
                                  <span>
                                    price basis{" "}
                                    {/* <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <Button variant="success" className="tooltips">
                                    <IoIosHelpCircle id="help" />
                                  </Button>
                                </OverlayTrigger>{" "} */}
                                  </span>
                                </td>
                                <td>{x.price_basis}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price PMT words</span>
                                </td>
                                <td>{x.price_pmt_in_words}</td>
                                <td id="td-right">
                                  <span>Shipping Term</span>{" "}
                                </td>
                                <td>{x.shipping_term}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>sales price margin</span>
                                </td>
                                <td>{x.sales_price_margin}</td>

                                <td id="td-right">
                                  {/* <span>sales price margin</span> */}
                                </td>
                                <td>{/* {x.sales_price_margin} */}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Delivery</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan From Date</span>
                                </td>
                                <td>{x.laycan_from_date}</td>
                                <td id="td-right">
                                  <span>Laycan</span>
                                </td>
                                <td>{x.laycan}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan to date</span>
                                </td>
                                <td>{x.laycan_to_date}</td>
                                <td id="td-right">
                                  {/* <span>laycan year to</span> */}
                                </td>
                                <td>{/* {x.laycan_year_to} */}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year</span>
                                </td>
                                <td>{x.laycan_year}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan Month</span>
                                </td>
                                <td>{x.laycan_month}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">Quantity</h4>
                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>
                                    Quantity
                                    {/* <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip}
                                >
                                  <Button variant="success" className="tooltips">
                                    <IoIosHelpCircle id="help" />
                                  </Button>
                                </OverlayTrigger>{" "} */}
                                  </span>
                                </td>
                                <td>{renderShipmentQty(x.quantity)}</td>
                                <td id="td-right">
                                  <span>quantity info</span>
                                </td>
                                <td>{x.quantity_info}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>shipment quantity tolerance</span>
                                </td>
                                <td>
                                  {renderShipmentQty(
                                    x.shipment_quantity_tolerance
                                  )}
                                </td>
                                <td id="td-right">
                                  <span>quantity Type</span>{" "}
                                </td>
                                <td>{x.quantity_type}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Created By </span>{" "}
                                </td>
                                <td>{x.created_by}</td>
                                <td id="td-right">
                                  <span>Last Modified By</span>{" "}
                                </td>
                                <td>{x.last_modified_by}</td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                <span className="a-a">
                                         TCF Count{" "}
                                                <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{renderShipmentQty(x.tcf_count)}</td>
                                <td id="td-right">
                                   <span className="a-a">
                                        No of Orders{" "}
                                                <Tooltip text={<span>No of Order against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>{" "}
                                </td>
                                <td>{x.no_of_orders}</td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                <span className="a-a">
                                         Product Count{" "}
                                                <Tooltip text={<span>No of Product thru Availability check</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{x.product_count}</td>
                                <td id="td-right">
                                  <span></span>{" "}
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>Shipment Month </span>{" "}
                                </td>
                                <td>{x.shipment_month}</td>
                                <td id="td-right">
                                <span className="a-a">
                                        Shipment Qty{" "}
                                                <Tooltip text={<span>IF (Order_Qty__c =0, Quantity__c , Order_Qty__c )</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                </td>
                                <td>{x.shipment_quantity}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Contract Products</span>
                              <span>
                                <i className=""></i>
                                New Contract Products
                              </span>
                              <span>
                                <i className=""></i>
                                Attach File
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Products</span>
                            </h4>
                            <div id="inner-heading"></div>
                            <hr></hr>
                          </div>
                          <table id="product" className="">
                            <thead>
                              <tr>
                                <th>Product No</th>
                                <th>Product Name</th>

                                <th>Product Code</th>
                                <th>Quantity MT</th>
                                <th>Status</th>
                                <th>Buyer Name </th>
                                <th>Laycan From Date </th>
                                <th>Laycan To Date</th>
                              </tr>
                            </thead>

                            {product.length > 0 ? (
                              <tbody>
                                {product.map((x, i) => (
                                  <tr>
                                    <td>
                                      <Link
                                        to={`/product/product-detail/${x.id}`}
                                      >
                                        {" "}
                                        {x.product_no}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/productmaster/product-detail/${x.product_master_id}`}
                                      >
                                        {x.product_name}
                                      </Link>
                                    </td>

                                    <td>
                                      <Link
                                        to={`/productmaster/product-detail/${x.product_master_id}`}
                                      >
                                        {x.product_code}
                                      </Link>
                                    </td>

                                    <td>{x.quantity_MT}</td>
                                    <td>{x.status}</td>
                                    <td>{x.buyer_name}</td>
                                    <td>{x.laycan_from_date}</td>
                                    <td>{x.laycan_to_date}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Trade Confirmation Form</span>
                            </h4>
                            <div id="inner-heading"></div>
                            <hr></hr>
                          </div>
                          <table id="tcf" className="">
                            <thead>
                              <tr>
                                <th>TCF No</th>
                                <th>Buyer</th>
                                <th>Supplier</th>
                                <th>Quantity (MT) </th>
                                <th>Contractual GAR (P) </th>
                                <th>Actual GAR (P) </th>
                                <th>Actual GAR (S) </th>
                                <th>Proposed Laycan From </th>
                                <th>Proposed Laycan To</th>
                              </tr>
                            </thead>

                            {tcfList.length > 0 ? (
                              <tbody>
                                {tcfList.map((x, i) => (
                                  <tr>
                                    <td>
                                      <Link to={"/tcf/tcf-details/" + x.id}>
                                        {x.tcf_no}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={`/accounts/${x.account_id}`}>
                                        {x.buyer}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={`/accounts/${x.supplier_id}`}>
                                        {x.supplier}
                                      </Link>
                                    </td>
                                    <td>{x.quantity_MT}</td>
                                    <td>{x.contractual_GAR_P}</td>
                                    <td>{x.actual_gar_p}</td>
                                    <td>{x.actual_gar_s}</td>
                                    <td>{x.proposed_laycan_from}</td>
                                    <td>{x.proposed_laycan_to}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Orders</span>
                            </h4>
                            <div id="inner-heading"></div>
                            <hr></hr>
                          </div>
                          <table id="orders" className="">
                            <thead>
                              <tr>
                                <th>Order Number </th>
                                <th>Status</th>
                                <th>Account Name </th>
                                <th>Supplier</th>
                                <th>Vessel Name </th>
                                <th>Ship Month </th>
                                <th>Shipment Qty </th>
                                <th>Laycan From Date </th>
                                <th>Supplier Invoice Price </th>
                                <th>Commercial Invoice Price</th>
                              </tr>
                            </thead>

                            {orders.length > 0 ? (
                              <tbody>
                                {orders.map((x, i) => (
                                  <tr>
                                    <td>
                                      <Link
                                        to={`/order/view-order-details/${x.id}`}
                                      >
                                        {x.order_no}
                                      </Link>
                                    </td>
                                    <td>{x.status}</td>
                                    <td>
                                      <Link to={`/accounts/${x.account_id}`}>
                                        {x.account_name}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to={`/accounts/${x.supplier_id}`}>
                                        {x.supplier}
                                      </Link>
                                    </td>
                                    <td>{x.vessel_name}</td>
                                    <td>{x.shipment_month}</td>
                                    <td>{x.shipment_qty}</td>
                                    <td>{x.laycan_from_date}</td>
                                    <td>{x.supplier_invoice}</td>
                                    <td>{x.commercial_invoice}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Open Activities</span>
                              <span>
                                <i className=""></i>
                                New Task
                              </span>
                              <span>
                                <i className=""></i>
                                New Event
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Activity History</span>
                              <span>
                                <i className=""></i>
                                Log a Call
                              </span>
                              <span>
                                <i className=""></i>
                                Mail Merge
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes & Attachments</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                              <span>
                                <i className=""></i>
                                Attach File
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>{" "}
          </div>{" "}
        </div>
        <Footer />
      </div>
    );
  }

  if (datas1.record_type === "Supplier - Spot") {
    return (
      <div className="priceAccount full-container" id="view-contract-ship">
        <Header />
        <div>
          <div className="row">
            <div className="col-md-2 recent-item">
              <RecentItems />
            </div>
            <div className="col-md-10 right-section">
              <div className="page-header" id="">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Contract Shipment{" "}
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard / shipment / Contract Shipment
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
           <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
          </button> */}
                  </div>
                </div>
              </div>

              {isEditMode ? (
                <>
                 <div className="tasks cons-shipment" id="view-page">
                    <div id="messageContainer"></div>
                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>

                    <Row className="mb-3">
                      <h4 className="heading">Contract Shipment Detail</h4>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Currency"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="currency"
                            defaultValue={datas1.currency || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select </option>
                            <option value="IDR">IDR </option>
                            <option value="USD">USD </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Record type"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Record type"
                            name="record_type"
                            className="record-type"
                            defaultValue={datas1.record_type || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Exchange rate"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Exchange rate"
                            name="exchange_rate"
                            defaultValue={datas1.exchange_rate || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan From Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={startDate}
                            onChange={handleStartDateChange}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            startDate={startDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>

                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Laycan To Date"
                          className="mb-3 datepick"
                        >
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            startDate={endDate}
                            placeholderText="Select"
                          />
                        </FloatingLabel>
                        {endDateError && (
                          <div
                            className="error-validations"
                            style={{ color: "red" }}
                          >
                            {endDateError}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_type"
                            placeholder="Price type"
                            defaultValue={datas1.price_type || ""}
                            onChange={(e) => {
                              handleChangeInput(e);
                              if (
                                e.target.value === "GcNewc" ||
                                e.target.value === "Fix Price"
                              ) {
                                // If price_type is "Fix Price", disable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = true;
                                document.getElementById(
                                  "sales_price_margin"
                                ).value = "";
                              } else if (
                                e.target.value !== "GcNewc" ||
                                e.target.value !== "Fix Price"
                              ) {
                                document.getElementById(
                                  "price"
                                ).disabled = true;
                                document.getElementById("price").value = "";
                              } else {
                                // Otherwise, enable sales_price_margin input
                                document.getElementById(
                                  "sales_price_margin"
                                ).disabled = false;
                              }
                            }}
                          >
                            <option value="">Select</option>
                            {getPrice.map((x) => {
                              return (
                                <option value={x.price_type_name}>
                                  {x.price_type_name}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>

                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity"
                            name="quantity"
                            defaultValue={datas1.quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity From"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity From"
                            name="quantity_from"
                            defaultValue={datas1.quantity_from || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Quantity To"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Quantity To"
                            name="quantity_to"
                            defaultValue={datas1.quantity_to || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Quantity Type"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="quantity_type"
                            placeholder="Quantity Type"
                            defaultValue={datas1.quantity_type || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="Base Quantity">Base Quantity</option>
                            <option value="Buyers Option">Buyers Option</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price"
                            id="price"
                            name="price"
                            disabled={
                              datas1.price_type !== "GcNewc" &&
                              datas1.price_type !== "Fix Price"
                            }
                            defaultValue={datas1.price || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Price pmt in words"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Price pmt in words"
                            name="price_pmt_in_words"
                            defaultValue={datas1.price_pmt_in_words || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Shipment Quantity Tolerance"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="Shipment Quantity Tolerance"
                            name="shipment_quantity_tolerance"
                            defaultValue={
                              datas1.shipment_quantity_tolerance || ""
                            }
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_from"
                            placeholder="Laycan Year From"
                            defaultValue={datas1.laycan_year_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year_to"
                            placeholder="Laycan Year To"
                            defaultValue={datas1.laycan_year_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month From"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_from"
                            placeholder="Laycan Month From"
                            defaultValue={datas1.laycan_month_from || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Month To"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_month_to"
                            placeholder="Laycan Month To"
                            defaultValue={datas1.laycan_month_to || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="No of Shipments"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Shipments"
                            name="no_of_shipments"
                            defaultValue={datas1.no_of_shipments || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Contract"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Contract"
                            name="contract"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            className="error-validation"
                            defaultValue={datas1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {companies.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                      <Form.Group as={Col} md="6"></Form.Group>
                      {datas1.price_type === "Fix Price" ? (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      ) : (
                        <Form.Group as={Col} md="6">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Sales price margin"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Sales price margin"
                              name="sales_price_margin"
                              id="sales_price_margin"
                              defaultValue={datas1.sales_price_margin || ""}
                              onChange={handleChangeInput}
                              disabled={
                                datas1.price_type === "GcNewc" &&
                                datas1.price_type === "Fix Price"
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      )}

                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract shipment status"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract_shipment_status"
                            placeholder="Contract shipment status"
                            value={
                              datas1.contract_shipment_status || "Completed"
                            }
                            onChange={handleChangeInput}
                          >
                            <option value="draft" selected>
                              Draft
                            </option>
                            <option value="Activated">Activated</option>
                            <option value="Completed" selected>
                              Completed
                            </option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Shipments - Arutmin">
                              Shipments - Arutmin
                            </option>
                            <option value="Cancelled with Penalty">
                              Cancelled with Penalty
                            </option>
                            <option value="Partially Completed">
                              Partially Completed
                            </option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Order quantity"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Order quantity"
                            name="order_quantity"
                            defaultValue={datas1.order_quantity || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"
                            defaultValue={datas1.price_basis || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Shipping Term"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="shipping_term"
                            placeholder="Shipping Term"
                            defaultValue={datas1.shipping_term || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">--None--</option>
                            <option value="FOB-B">FOB-B</option>
                            <option value="FOB-MV">FOB-MV</option>
                            <option value="CFR-MV">CFR-MV</option>
                            <option value="CIF-MV">CIF-MV</option>
                            <option value="FAS">FAS</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Laycan Year"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="laycan_year"
                            placeholder="Laycan Year"
                            defaultValue={datas1.laycan_year || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">---None---</option>
                            <option value="2018">2018</option>
                            <option value="2019">2019</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="6"></Form.Group>
                      <Form.Group as={Col} md="6" className="tooltips">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="TCF Count"
                          className="mb-3"
                        >
                          <Form.Control
                            type="number"
                            placeholder="TCF Count"
                            name="tcf_count"
                            defaultValue={datas1.tcf_count || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                        <Tooltip text={<span>Total No of TCF against this Shipment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                      </Form.Group>
                    </Row>

                    <p className="edit-btn">
                      <input
                        type="submit"
                        className="account-save"
                        onClick={handleSaveEdit}
                        value={isSubmitted ? "Saving" : "Save"}
                        disabled={isSubmitted}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleCancelEdit}
                      >
                        Cancel
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <div className="tasks cons-shipment" id="view-page">
                  {datas.length > 0 ? (
                    datas.map((x) => (
                      <>
                        {/* <p id="top-header"><span class="button add-accountrecord" aria-describedby="popup-54"> RW Payment/Receipt LineItem </span></p> */}

                        <p className="edit-btn btn-disabled">
                          <button id="edits" onClick={handleEditClick}>
                            Edit
                          </button>
                          <Link to={`/contract-shipment/create-view-list`}>
                            {" "}
                            <button id="edits">Cancel</button>
                          </Link>
                          {!datas1.laycan_from_date ||
                          !datas1.laycan_to_date ? (
                            <span>
                              <button
                                id="edits"
                                className="tcf-hide"
                                onClick={createProduct}
                              >
                                Create Product
                              </button>
                              <Link>
                                <button
                                  id="edits"
                                  className="tcf-hide"
                                  onClick={createTcf}
                                >
                                  Create TCF
                                </button>
                              </Link>
                              <button
                                id="edits"
                                className="tcf-hide"
                                onClick={createOrder}
                              >
                                Create Order
                              </button>
                            </span>
                          ) : (
                            <>
                              <button id="edits" onClick={createProduct}>
                                Create Product
                              </button>
                              <Link>
                                <button id="edits" onClick={createTcf}>
                                  Create TCF
                                </button>
                              </Link>
                              <button id="edits" onClick={createOrder}>
                                Create Order
                              </button>
                            </>
                          )}
                          {/* <button className='btn btn-primary' id='edits' onClick={noOfshipment}>Contract Shipment Details</button>  */}
                        </p>
                        <div className="">
                          <div className="row">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                Contract Shipment Details
                              </h4>

                              <hr></hr>
                            </div>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>{" "}
                                </td>
                                <td>{x.currency}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>record type</span>{" "}
                                </td>
                                <td>{x.record_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>exchange rate</span>{" "}
                                </td>
                                <td>{x.exchange_rate}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan From Date</span>{" "}
                                </td>
                                <td>{x.laycan_from_date}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Laycan To Date</span>{" "}
                                </td>
                                <td>{x.laycan_to_date}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price type</span>{" "}
                                </td>
                                <td>{x.price_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity from</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity_from)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity to</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.quantity_to)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity type</span>{" "}
                                </td>
                                <td>{x.quantity_type}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.price)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price pmt in words</span>{" "}
                                </td>
                                <td>{x.price_pmt_in_words}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>laycan year from</span>{" "}
                                </td>
                                <td>{x.laycan_year_from}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year to</span>{" "}
                                </td>
                                <td>{x.laycan_year_to}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month from</span>{" "}
                                </td>
                                <td>{x.laycan_month_from}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan month to</span>{" "}
                                </td>
                                <td>{x.laycan_month_to}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>no of shipments</span>{" "}
                                </td>
                                <td>{x.no_of_shipments}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>contract</span>{" "}
                                </td>
                                <td>
                                  <Link
                                    to={`/contract/contract-details/${x.contract_id}`}
                                  >
                                    {" "}
                                    {x.contract}{" "}
                                  </Link>
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>sales price margin</span>{" "}
                                </td>
                                <td>{x.sales_price_margin}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>contract shipment status</span>{" "}
                                </td>
                                <td>
                                  {x.contract_shipment_status || "Completed"}
                                </td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>order quantity</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.order_quantity)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quantity info</span>{" "}
                                </td>
                                <td>{x.quantity_info}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>price basis</span>{" "}
                                </td>
                                <td>{x.price_basis}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>shipping term</span>{" "}
                                </td>
                                <td>{x.shipping_term}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>laycan year</span>{" "}
                                </td>
                                <td>{x.laycan_year}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>TCFcount</span>{" "}
                                </td>
                                <td>{renderShipmentQty(x.tcf_count)}</td>
                                <td id="td-right">
                                  <span></span>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Open Activities</span>
                                <span>
                                  <i className=""></i>
                                  New Task
                                </span>
                                <span>
                                  <i className=""></i>
                                  New Event
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          {/* <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment Information</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-ship" className="">
                              <thead>
                                <tr>
                                  <th>contract ship code</th>
                                  <th>parent shipment</th>
                                  <th>price</th>
                                  <th>price basis</th>
                                  <th>quantity</th>
                                </tr>
                              </thead>

                              {contractShipmentLength > 0 ? (
                                <tbody>
                                  {contractShipment.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/contract-shipment/detail/${y.id}`}
                                        >
                                          {y.contract_ship_code}
                                        </Link>
                                      </td>
                                      <td>{y.parent_shipment}</td>
                                      <td>{y.price}</td>
                                      <td>{y.price_basis}</td>
                                      <td>{y.quantity}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row> */}
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Activity History</span>
                                <span>
                                  <i className=""></i>
                                  Log a Call
                                </span>
                                <span>
                                  <i className=""></i>
                                  Mail Merge
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Notes & Attachments</span>
                                <span>
                                  <i className=""></i>
                                  New Note
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Products</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Products
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Trade Confirmation Form</span>
                              </h4>
                              <div id="inner-heading"></div>
                              <hr></hr>
                            </div>
                            <table id="tcf" className="">
                              <thead>
                                <tr>
                                  <th>TCF No</th>
                                  <th>Buyer</th>
                                  <th>Supplier</th>
                                  <th>Quantity (MT) </th>
                                  <th>Contractual GAR (P) </th>
                                  <th>Actual GAR (P) </th>
                                  <th>Actual GAR (S) </th>
                                  <th>Proposed Laycan From </th>
                                  <th>Proposed Laycan To</th>
                                </tr>
                              </thead>

                              {tcfList.length > 0 ? (
                                <tbody>
                                  {tcfList.map((x, i) => (
                                    <tr>
                                      <td>
                                        <Link to={"/tcf/tcf-details/" + x.id}>
                                          {x.tcf_no}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.account_id}`}>
                                          {x.buyer}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/accounts/${x.supplier_id}`}>
                                          {x.supplier}
                                        </Link>
                                      </td>
                                      <td>{x.quantity_MT}</td>
                                      <td>{x.contractual_GAR_P}</td>
                                      <td>{x.actual_gar_p}</td>
                                      <td>{x.actual_gar_s}</td>
                                      <td>{x.proposed_laycan_from}</td>
                                      <td>{x.proposed_laycan_to}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipments</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Shipment
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-ship" className="">
                              <thead>
                                <tr>
                                  <th>contract ship code</th>
                                  <th>Contract</th>
                                  <th>Quantity</th>
                                  <th>Laycan</th>
                                  <th>Product Count </th>
                                  <th>TCF Count </th>
                                  <th>No of Orders </th>
                                  <th>Pricing</th>
                                  <th>Order Qty</th>
                                </tr>
                              </thead>

                              {contractShipmentLength > 0 ? (
                                <tbody>
                                  {contractShipment.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/contract-shipment/detail/${y.id}`}
                                        >
                                          {y.contract_ship_code}
                                        </Link>
                                      </td>
                                      <td>{y.contract}</td>
                                      <td>{y.quantity}</td>
                                      <td>{y.laycan}</td>
                                      <td>{y.product_count}</td>
                                      <td>{y.tcf_count}</td>
                                      <td>{y.no_of_orders}</td>
                                      <td>{y.pricing}</td>
                                      <td>{y.order_qty}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>History Information</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="historys" className="">
                              <thead>
                                <tr>
                                  <th>Field Name</th>
                                  <th>New Value</th>
                                  <th>Old Value</th>
                                  <th>Changed By</th>
                                  <th>Change Time</th>
                                </tr>
                              </thead>

                              {history.length > 0 ? (
                                <tbody>
                                  {history.map((y, i) => (
                                    <tr>
                                      <td>{y.field_name}</td>
                                      <td>{y.new_value}</td>
                                      <td>{y.old_value}</td>
                                      <td>{y.changed_by}</td>
                                      <td>{y.change_time}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContractShippmentDetails;
