import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $, { get } from 'jquery';
import axios from "axios";
import { useParams , useLocation,Link } from "react-router-dom"; 
import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useParams , useLocation, Link, useNavigate } from "react-router-dom"; 

function HbaUpdate() { 
  const location=useLocation();

  const {pricebookName, id}=location.state;
  console.log(pricebookName)
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  // console.log(pricebookName)
   
  const [datas, setDatas] = useState([]);
  useEffect(() => {   
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/all_hba/'+pricebookName, { 
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.records;     
      setDatas(data);   
      console.log(data)     
    } catch (error) {
      console.error(error);
    }
  };
   
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
const valueChanged = (val, id, key) => {
  setDatas(prevState => {
    const newState = prevState.map(obj => {
      if (obj.id === id) {
        const inputValue = val.target.value;

        // Only parse float for "platts" and "platts_K5900"
        let parsedValue;
        if (key === "platts" || key === "platts_K5900") {
          // Attempt to parse as float, fallback to 0 if NaN
          parsedValue = parseFloat(inputValue);
          if (isNaN(parsedValue)) parsedValue = 0; // Default to 0 if parsing fails
        } else if (key === "hba_remarks" || key === "hba_status") {
          parsedValue = inputValue; // Keep as string for specific fields
        } else {
          parsedValue = isNaN(inputValue) ? inputValue : parseFloat(inputValue); // General case
        }

        return { ...obj, [key]: parsedValue };
      }
      return obj;
    });

    return newState;
  });
};

  
  

const submitData = async () => { 
  
  const val = pricebookName.replace(/%20/g, ' ');

  // Format each field in `datas` to ensure the correct type (number or string)
  const formattedData = datas.map((record) => {
    const formattedRecord = {};
    Object.keys(record).forEach((key) => {
      const value = record[key];
      if (typeof value === 'string' && !isNaN(value) && (key === "platts" || key === "platts_K5900")) {
        // Convert to float for specific numeric fields
        formattedRecord[key] = parseFloat(value);
      } else if (typeof value === 'string' && isNaN(value)) {
        // Keep as string for other fields
        formattedRecord[key] = value;
      } else {
        // Fallback for other fields that should remain as a number
        formattedRecord[key] = parseFloat(value) || value;
      }
    });
    return formattedRecord;
  });

  const responseData = { "records": formattedData };
  console.log(JSON.stringify(responseData));

  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    try {
      const response = await axios.put(`${API_URL}update/hba_values/${pricebookName}`, JSON.stringify(responseData), {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      fetchData();
      // window.location.reload();

      toast.success("Pricebook HBA update successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Failed to update data:", error);
      toast.error("Failed to update Pricebook HBA", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Pricebook HBA Edit</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Pricebook HBA Edit </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>
    {/* <Form> */} 
    <div className="hba-section">
    <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={submitData}  /><Link to={`/pricebook-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
    {/* <input type="submit" onClick={submitData}  className="addaccount-save hba-submit-top" value='Save'/> */}
            {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
            <table className="table table-bordered hba">
            <thead>
            <tr>
                <th colSpan='2'> </th>
                <th>Daily</th>
                <th>Daily</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Weekly</th>
                <th>Current Month</th>
                <th>Status</th>
                <th>Remakrs</th>
                <th colSpan='4'>Adjusted CV - 6322</th>
                <th colSpan='2'>HBA</th>

            </tr>
            <tr>
                <th>Date</th>
                <th>Day</th>
                <th>Platts K5900</th>
                <th>GCNEW 6322	</th>
                <th>NEX</th>
                <th>ICI1 6500</th>
                <th>ICI2 5800</th>
                <th>ICI3 5000</th>
                <th>ICI4 4200</th>
                <th>ICI5 3400</th>
                <th>RB</th>
                <th>DES ARA	</th>
                <th>HBA</th>
                <th></th>
                <th></th>
                <th>Platts</th>
                <th>GCNEWC</th>
                <th>NEX</th>
                <th>ICI 1</th>
                <th>Daily Estimate</th>
                <th>MTD Estimate</th>

            </tr>
            </thead>
            
           
            <tbody>
            
            {datas.map((x)=>(
              <>
              {x.start_no===1 ?(
                <tr>
                <td Style="width:5.5%"><input type="text" name='hba_date' defaultValue={x.hba_date} /></td>
                <td Style="width:5.5%"><input type="text" name='hba_day' defaultValue={x.hba_day} /></td>
                <td ><input type="text" name='platts_K5900' defaultValue={x.platts_K5900} onChange={(e)=>valueChanged(e,x.id,"platts_K5900")}/></td>
                <td><input type="text" name='GCNEWC_6322' defaultValue={x.GCNEWC_6322} onChange={(e)=>valueChanged(e,x.id,"GCNEWC_6322")}/></td>
                <td><input type="text" name='NEX' defaultValue={x.NEX} onChange={(e)=>valueChanged(e,x.id,"NEX")}/></td>
                <td><input type="text" name='ICI1_6500' defaultValue={x.ICI1_6500} onChange={(e)=>valueChanged(e,x.id,"ICI1_6500")}/></td>
                {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_ICI2_5800' defaultValue={x.weekly_ICI2_5800} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI2_5800")}/></td>
                ):(
                    <td><input type="text"  name='weekly_ICI2_5800' defaultValue={x.weekly_ICI2_5800} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI2_5800")}/></td> 
                )}
                {x.hba_day ==='Friday' ? (
                <td><input type="text"  name='weekly_ICI3_5000' defaultValue={x.weekly_ICI3_5000} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI3_5000")}/></td>
                ):(
                    <td><input type="text" name='weekly_ICI3_5000' defaultValue={x.weekly_ICI3_5000} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI3_5000")}/></td>
                )}
                 {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_ICI4_4200' defaultValue={x.weekly_ICI4_4200} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI4_4200")}/></td>
                ):(
                    <td><input type="text"  name='weekly_ICI4_4200'  defaultValue={x.weekly_ICI4_4200}   onChange={(e)=>valueChanged(e,x.id,"weekly_ICI4_4200")}/></td>
                )}
                
                {x.hba_day ==='Friday' ? (
                <td><input type="text"  name='weekly_ICI5_3400' defaultValue={x.weekly_ICI5_3400} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI5_3400")}/></td>
                ):(
                    <td><input type="text" name='weekly_ICI5_3400' defaultValue={x.weekly_ICI5_3400}  onChange={(e)=>valueChanged(e,x.id,"weekly_ICI5_3400")}/></td>
                )}
                  {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_RB' defaultValue={x.weekly_RB} onChange={(e)=>valueChanged(e,x.id,"weekly_RB")}/></td>
                ):(
                    <td><input type="text" name="weekly_RB" defaultValue={x.weekly_RB}  onChange={(e)=>valueChanged(e,x.id,"weekly_RB")}/></td>
                )}
                    {x.hba_day ==='Friday' ? (
                <td><input type="text" name="weekly_DES_ARA" defaultValue={x.weekly_DES_ARA} onChange={(e)=>valueChanged(e,x.id,"weekly_DES_ARA")}/></td>
                ):(
                    <td><input type="text"  name='weekly_DES_ARA' defaultValue={x.weekly_DES_ARA} onChange={(e)=>valueChanged(e,x.id,"weekly_DES_ARA")}/></td>
                )}
                
                {x.hba_day ==='Friday' ? (
                <td><input type="text" name="month_HBA" defaultValue={x.month_HBA}  onChange={(e)=>valueChanged(e,x.id,"month_HBA")}/></td>
                ):(
                    <td><input type="text" name="month_HBA" defaultValue={x.month_HBA}  onChange={(e)=>valueChanged(e,x.id,"month_HBA")} /></td>
                )}
                 
                <td><input type="text" name="hba_status" defaultValue={x.hba_status} onChange={(e)=>valueChanged(e,x.id,"hba_status")}/></td>
                <td><input type="text" name="hba_remarks" defaultValue={x.hba_remarks}  onChange={(e)=>valueChanged(e,x.id,"hba_remarks")}/></td>
                
                <td><input type="text" name="platts" defaultValue={x.platts} disabled onChange={(e)=>valueChanged(e,x.id,"platts")}/></td>
                <td><input type="text" name="hba_GCNEWC" defaultValue={x.hba_GCNEWC} disabled onChange={(e)=>valueChanged(e,x.id,"hba_GCNEWC")}/></td>
                <td><input type="text" name="hba_NEX" defaultValue={x.hba_NEX} disabled onChange={(e)=>valueChanged(e,x.id,"hba_NEX")}/></td>
                <td><input type="text" name="hba_ICI_1" defaultValue={x.hba_ICI_1} disabled onChange={(e)=>valueChanged(e,x.id,"hba_ICI_1")}/></td>
                
                
                <td><input type="text" name="daily_estimate" defaultValue={x.daily_estimate} disabled onChange={(e)=>valueChanged(e,x.id,"daily_estimate")}/></td>
                <td><input type="text" name="mtd_estimate" defaultValue={x.mtd_estimate}  disabled onChange={(e)=>valueChanged(e,x.id,"mtd_estimate")}/></td>
                
            </tr>
            
              ):(
                <tr>
                <td Style="width:5.5%"><input type="text" name='hba_date' defaultValue={x.hba_date} disabled /></td>
                <td Style="width:5.5%"><input type="text" name='hba_day' defaultValue={x.hba_day} disabled /></td>
                <td ><input type="text" name='platts_K5900' defaultValue={x.platts_K5900} onChange={(e)=>valueChanged(e,x.id,"platts_K5900")}/></td>
                <td><input type="text" name='GCNEWC_6322' defaultValue={x.GCNEWC_6322} onChange={(e)=>valueChanged(e,x.id,"GCNEWC_6322")}/></td>
                <td><input type="text" name='NEX' defaultValue={x.NEX} onChange={(e)=>valueChanged(e,x.id,"NEX")}/></td>
                <td><input type="text" name='ICI1_6500' defaultValue={x.ICI1_6500} onChange={(e)=>valueChanged(e,x.id,"ICI1_6500")}/></td>
                {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_ICI2_5800' defaultValue={x.weekly_ICI2_5800} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI2_5800")}/></td>
                ):(
                    <td><input type="text"  name='weekly_ICI2_5800' defaultValue={x.weekly_ICI2_5800} disabled /></td> 
                )}
                {x.hba_day ==='Friday' ? (
                <td><input type="text"  name='weekly_ICI3_5000' defaultValue={x.weekly_ICI3_5000} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI3_5000")}/></td>
                ):(
                    <td><input type="text" name='weekly_ICI3_5000' defaultValue={x.weekly_ICI3_5000}  disabled/></td>
                )}
                 {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_ICI4_4200' defaultValue={x.weekly_ICI4_4200} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI4_4200")}/></td>
                ):(
                    <td><input type="text"  name='weekly_ICI4_4200'  defaultValue={x.weekly_ICI4_4200} disabled  /></td>
                )}
                
                {x.hba_day ==='Friday' ? (
                <td><input type="text"  name='weekly_ICI5_3400' defaultValue={x.weekly_ICI5_3400} onChange={(e)=>valueChanged(e,x.id,"weekly_ICI5_3400")}/></td>
                ):(
                    <td><input type="text" name='weekly_ICI5_3400' defaultValue={x.weekly_ICI5_3400} disabled /></td>
                )}
                  {x.hba_day ==='Friday' ? (
                <td><input type="text" name='weekly_RB' defaultValue={x.weekly_RB} onChange={(e)=>valueChanged(e,x.id,"weekly_RB")}/></td>
                ):(
                    <td><input type="text" name="weekly_RB" defaultValue={x.weekly_RB} disabled /></td>
                )}
                    {x.hba_day ==='Friday' ? (
                <td><input type="text" name="weekly_DES_ARA" defaultValue={x.weekly_DES_ARA} onChange={(e)=>valueChanged(e,x.id,"weekly_DES_ARA")}/></td>
                ):(
                    <td><input type="text"  name='weekly_DES_ARA' defaultValue={x.weekly_DES_ARA}   disabled/></td>
                )}
                
                {x.hba_day ==='Friday' ? (
                <td><input type="text" name="month_HBA" defaultValue={x.month_HBA}  onChange={(e)=>valueChanged(e,x.id,"month_HBA")}/></td>
                ):(
                    <td><input type="text" name="month_HBA" defaultValue={x.month_HBA} disabled  /></td>
                )}
                 
                <td><input type="text" name="hba_status" defaultValue={x.hba_status} onChange={(e)=>valueChanged(e,x.id,"hba_status")}/></td>
                <td><input type="text" name="hba_remarks" defaultValue={x.hba_remarks}  onChange={(e)=>valueChanged(e,x.id,"hba_remarks")}/></td>
                
                <td><input type="text" name="platts" defaultValue={x.platts} onChange={(e)=>valueChanged(e,x.id,"platts")} disabled/></td>
                <td><input type="text" name="hba_GCNEWC" defaultValue={x.hba_GCNEWC}  onChange={(e)=>valueChanged(e,x.id,"hba_GCNEWC")} disabled/></td>
                <td><input type="text" name="hba_NEX" defaultValue={x.hba_NEX}  onChange={(e)=>valueChanged(e,x.id,"hba_NEX")} disabled/></td>
                <td><input type="text" name="hba_ICI_1" defaultValue={x.hba_ICI_1} onChange={(e)=>valueChanged(e,x.id,"hba_ICI_1")} disabled/></td>
                
                
                <td><input type="text" name="daily_estimate" defaultValue={x.daily_estimate} disabled onChange={(e)=>valueChanged(e,x.id,"daily_estimate")}/></td>
                <td><input type="text" name="mtd_estimate" defaultValue={x.mtd_estimate} disabled  onChange={(e)=>valueChanged(e,x.id,"mtd_estimate")}/></td>
                
            </tr>
            
              )}
              </>

            ))}
            
           </tbody>
           
           
           
           
        </table>
        {/* <input type="submit"  className="addaccount-save hba-submit" value='Save'/> */}
        <p className='edit-cancel'><input type="submit" className="account-save" value="Save" onClick={submitData} /><Link to={`/pricebook-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
        <p></p><p></p>
           
    </div>
    <Footer/>
    </div>
  );
}

export default HbaUpdate;
