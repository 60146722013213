import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from './config';
import { AES, enc } from 'crypto-js';
import { useNavigate, useLocation } from 'react-router-dom';

export const TokenContext = createContext();

const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

const isTokenValid = (token) => {
  try {
    const decryptedAccessToken = AES.decrypt(token, secret_key).toString(enc.Utf8);
    if (!decryptedAccessToken) return false;

    const tokenParts = decryptedAccessToken.split('.');
    if (tokenParts.length !== 3) return false; // Token should have three parts

    const payload = JSON.parse(atob(tokenParts[1]));
    const expirationDate = new Date(payload.exp * 1000);
    return expirationDate > new Date();
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem('access_token'));
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [userName, setUsername] = useState(() => localStorage.getItem('username'));
  const [loginError, setLoginError] = useState(null); // Error state
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnlineStatus = useCallback(() => {
    setIsOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [handleOnlineStatus]);


  useEffect(() => {
    const checkAuthentication = async () => {
      const storedToken = localStorage.getItem('access_token');
      if (storedToken && !isTokenValid(storedToken)) {
        localStorage.clear()

        setToken(null);
        setUsername(null);
        if (location.pathname !== '/login') {
          navigate('/login');
        }
        setIsLoading(false);
        return;
      }

      // Proceed if the token is valid or doesn't exist
      if (!isOnline) {
        const intervalId = setInterval(() => {
          if (navigator.onLine) {
            clearInterval(intervalId);
            checkAuthentication();
          }
        }, 1000);
        return;
      }

      if (storedToken) {
        setToken(storedToken);
        setUsername(localStorage.getItem('username'));
      } else {
        if (location.pathname !== '/login') {
          navigate('/login');
        }
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, [navigate, isOnline, location.pathname]);

  // useEffect(() => {
  //   const checkAuthentication = async () => {
  //     if (!isOnline) {
  //       const intervalId = setInterval(() => {
  //         if (navigator.onLine) {
  //           clearInterval(intervalId);
  //           checkAuthentication();
  //         }
  //       }, 1000);
  //       return;
  //     }

  //     const storedToken = localStorage.getItem('access_token');
  //     const storedUsername = localStorage.getItem('username');

  //     if (!storedToken || !isTokenValid(storedToken)) {
  //       localStorage.clear(); // Clear local storage if token is invalid
  //       if (location.pathname !== '/login') {
  //         navigate('/login');
  //       }
  //       setIsLoading(false);
  //       return;
  //     }

  //     setToken(storedToken);
  //     setUsername(storedUsername);
  //     setIsLoading(false);
  //   };

  //   checkAuthentication();
  // }, [navigate, isOnline, location.pathname]);

  const login = async (email, password) => {
    if (!isOnline) {
      console.error('No internet connection');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}login`, { email, password });
      const encryptedAccessToken = AES.encrypt(response.data.access_token, secret_key).toString();
      const userName = response.data.username;
      localStorage.setItem('access_token', encryptedAccessToken);
      localStorage.setItem('username', userName);
      setToken(encryptedAccessToken);
      setUsername(userName);
      setLoginError(null); // Clear any previous error

      navigate('/');

    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Invalid credentials. Please try again.'); // Set error message
    }
  };

  const refreshToken = useCallback(async () => {
    if (isRefreshing) return; // Prevent multiple concurrent refreshes
    setIsRefreshing(true);

    try {
      const storedToken = localStorage.getItem('access_token');
      if (!storedToken) {
        navigate('/login');
        return;
      }

      const decryptedAccessToken = AES.decrypt(storedToken, secret_key).toString(enc.Utf8);
      const response = await axios.post(`${API_URL}refresh`, { token: decryptedAccessToken });

      const newEncryptedToken = AES.encrypt(response.data.access_token, secret_key).toString();
      localStorage.setItem('access_token', newEncryptedToken);
      setToken(newEncryptedToken);

    } catch (error) {
      console.error('Token refresh error:', error);
      localStorage.clear();
      navigate('/login');
    } finally {
      setIsRefreshing(false);
    }
  }, [navigate, isRefreshing]);

  useEffect(() => {
    if (token) {
      // Refresh token every hour
      const intervalId = setInterval(refreshToken, 60 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [token, refreshToken]);

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setUsername(null);
    navigate('/login');
  };

  useEffect(() => {
    if (!token && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [token, navigate, location.pathname]);

  useEffect(() => {
    if (loginError) {
      alert(loginError); // Display error message
    }
  }, [loginError]);

  return (
    <TokenContext.Provider value={{ token, login, logout, userName }}>
      {isLoading ? <p>Loading...</p> : children}
    </TokenContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
