import React, { useState, useEffect } from "react";
import axios from "axios";
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../Footer";
import { useLocation , Link} from "react-router-dom";
import { API_URL } from '../config';
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function BuyerDebitNoteFields({ data, index, handleInputChange }) {

  return (


    <td>
      <select
        name="line_item_type"
        value={data.line_item_type}
        className="form-control line-item-type"
        onChange={(e) => handleInputChange(index, e)}
      >
        <option value=''>---Select---</option>
        <option value="GCV Variation">GCV Variation</option>
        <option value="Price Difference">Price Difference</option>
        <option value="Dead Freight Receivable">Dead Freight Receivable</option>
        <option value="Vessel Detention">Vessel Detention</option>
        <option value="Despatch">Umpire Analysis - PENDING</option>
        <option value="Demurrage">Demurrage</option>
      </select>
    </td>
  );
}

function SupplierFields({ data, index, handleInputChange }) {
  return (
    <td>
      <select
        name="name"
        value={data.name}
        onChange={(e) => handleInputChange(index, e)}
      >
        {/* Add your dropdown options for "Supplier" here */}
        <option value="Option3">Option 3</option>
        <option value="Option4">Option 4</option>
      </select>
    </td>
  );
}

function Postrwpaydetails() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
    const location = useLocation();
    const [updateData,setFormDataUpdate]=useState([]);

  const { ids, recordtype,order_id } = location.state;

  const post_id=parseInt(ids,10)
  const navigate=useNavigate()
  const [formData1, setFormData1] = useState({

    record_type:'',

  });
// console.log(recordorder_idtype)
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/postship_byID/" + post_id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      const order_details = response.data.postshipment_details[0];
      setFormDataUpdate(order_details);
      console.log(order_details)

    } catch (error) {
      console.error(error);
    }
  };

  const [formData, setFormData] = useState(() => {
    // Initialize formData from localStorage if available, otherwise use a default value
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : [{order: "",
      post_ship_document: "",
      line_item_type: "",
      line_item:"",
      unit_rate:"",
      exchange_rate:"",
      quantity:"",
      description:"",
      amount:"",



   buyer: recordtype }];
  });

  useEffect(() => {
    // Save formData to localStorage whenever it changes
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    
    // Update the specific field
    updatedFormData[index][name] = value;
  
    // Automatically calculate the amount if unit_rate or quantity is changed
    if (name === "unit_rate" || name === "quantity") {
      const unitRate = parseFloat(updatedFormData[index].unit_rate) || 0;
      const quantity = parseFloat(updatedFormData[index].quantity) || 0;
      updatedFormData[index].amount = (unitRate * quantity).toFixed(2); // Keep 2 decimal places
    }
  
    setFormData(updatedFormData);
  };
  

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({ ...formData1, [name]: value });
  };
  const handleAddField = () => {
    setFormData([...formData, {
    order: "",
    post_ship_document: "",
    line_item_type: "",
    line_item:"",
    unit_rate:"",
    exchange_rate:"",
    quantity:"",
    description:"",
    amount:"",



 buyer: recordtype }]);
  };

  const handleRemoveField = (index) => {
    if (index === 0) {
      return;
    }
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  const [responseId,setId]=useState({})
  console.log(responseId)
  const renderNameFields = (data, index) => {
    if (data.buyer === 'Buyer - Debit Note') {
      return (
        <BuyerDebitNoteFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else if (data.buyer === "Supplier") {
      return (
        <SupplierFields
          data={data}
          index={index}
          handleInputChange={handleInputChange}
        />
      );
    } else {
      return null;
    }
  };

  console.log(ids)

  const handleSubmit = (e) => {


    const requestData = {
      add_lineitem: formData,

    };

    sendDataToAPI2(requestData);
  };




  const sendDataToAPI2 = async (requestData) => {
    const order_detail = {
        "currency":updateData.currency,
        "exchange_rate":updateData.exchange_rate,
        "company":updateData.company,
        "account":updateData.account,
        "document_date":updateData.document_date,
        "document_reference_no":updateData.document_reference_no,
        "description":updateData.description,
        "status":updateData.status,
        "total_amount":updateData.total_amount,
        "total_paid_or_received_amount":updateData.total_paid_or_received_amount,
        "total_balance_due":updateData.total_balance_due,
        "created_by":updateData.created_by,
        "last_modified_by":updateData.last_modified_by,
        "locked": true,
        "record_type":recordtype,
        "owner":updateData.owner,
        "debit_note_no":updateData.debit_note_no,
        "rwood_bank_master":updateData.rwood_bank_master,
        "amount_in_words":updateData.amount_in_words,
        "payment": true,
        "del_paid_or_received_date": "2023-02-22",
        "del_paid_or_received_amount":updateData.payment,
        "del_balance_due":updateData.del_balance_due,
    };
    const responseData=JSON.stringify(order_detail)
    console.log(responseData)
    const id = 1;
    const api1Url = `${API_URL}add/postship/rw_pay_recieve/${post_id}`;
    // const api2Url = `${API_URL}add_line_item/${ids}/${order_id}`; // Replace with the URL of the second API

    try {
      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
        const response1 = await axios.post(api1Url, responseData, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${decryptedAccessToken}`
          },
        });
        // setId(response1.data.rw_id)

        if (response1.status === 200) {
            $('.clear').val('');
          console.log("Data sent to API 1 successfully");
          // Assuming the response from API 1 contains an 'id' field, you can extract it like this:
          const response1Id = response1.data.rw_id;
          console.log(response1Id)
          // Now, you can use response1Id in the second API call
          const response2 = await axios.post(`${API_URL}add_postshipment_lineitem/${post_id}/${response1Id}`, requestData, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${decryptedAccessToken}`
            },
          });
          console.log(response2)
          if (response2.status === 200) {
            //swindow.location.reload();
            toast.success('Added Successfully', {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              navigate(`/postshipment/postshipment-details/${post_id}`)
            console.log("Data sent to  API 2 successfully");

          } else {
            console.error(`Failed to send data to API 2. Status code: ${response2.status}`);
          }
        } else {
          console.error(`Failed to send data to API 1. Status code: ${response1.status}`);
        }
      } else {
        console.error("No access token found.");
      }
    } catch (error) {
      console.error("Error sending data to API 1 or API 2:", error);
    }
  };























//   const sendDataToAPI1 = async (requestData) => {
//     const id = 1;
//     const api1Url = `${API_URL}add/rw_pay_recieve/${ids}`;

//     try {
//       const encryptedAccessToken = localStorage.getItem('access_token');
//       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
//         const response = await axios.post(api1Url, requestData, {
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${decryptedAccessToken}`
//           },
//         });
//         setId(response.data.rw_id)
//         if (response.status === 200) {
//           console.log("Data sent to API 1 successfully");
//         } else {
//           console.error(`Failed to send data to API 1. Status code: ${response.status}`);
//         }
//       } else {
//         console.error("No access token found.");
//       }
//     } catch (error) {
//       console.error("Error sending data to API 1:", error);
//     }
//   };

  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };



//   const sendDataToAPI2 = async (requestData) => {
//     const id = 1;
//     const api2Url = `${API_URL}add_line_item/${ids}/${responseId}`;

//     try {
//       const encryptedAccessToken = localStorage.getItem('access_token');
//       const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
//     const res=JSON.stringify(requestData);
//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
//         const response = await axios.post(api2Url, res, {
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${decryptedAccessToken}`
//           },
//         });
//         console.log(response)
//         if (response.status === 200) {
//           console.log("Data sent to API 1 successfully");
//         } else {
//           console.error(`Failed to send data to API 1. Status code: ${response.status}`);
//         }
//       } else {
//         console.error("No access token found.");
//       }
//     } catch (error) {
//       console.error("Error sending data to API 1:", error);
//     }
//   };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch(API_URL+"add/rw_lineitem", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       console.log(response);
  //     } else {
  //       // Handle error
  //       console.error("Error submitting data.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormDataUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div>
        <Header/>
        <div className="page-header" id="">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Add Payable</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Payable / Add Payable
              </li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
            </div>
          </div>
        </div>
        <div className="tasks">
        <p className='edit-cancel'> <Link to={`/postshipment/postshipment-details/${ids}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
        <Row className="mb-3">
            <h4 className="heading">RW Payable Receivable Information</h4>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="RW Payable/Receivable"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="RW Payable/Receivable"
                  name="order"

                  defaultValue={updateData.order || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Company"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="company"
                  placeholder="Company"
                  value={updateData.company}
                  onChange={handleChangeInput}
                >
                  <option value="">Select</option>
                  {company.map((x) => {
                    return (
                      <option value={x.company_name}>{x.company_name}</option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Record Type"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Record Type"
                  name="record_type"
                  defaultValue={recordtype}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Currency"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="currency"
                  placeholder="Currency"
                  value={updateData.currency}
                  onChange={handleChangeInput}
                >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="IDR">IDR</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Owner"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Owner"
                  name="owner"
                  defaultValue={updateData.order_owner || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Exchange Rate"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Exchange Rate"
                  name="exchange_rate"
                  className="clear"
                  value={updateData.exchange_rate || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Document Reference No"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Document Reference No"
                  style={{ height: "100px" }}
                  className="clear"
                  name="document_reference_no"
                  defaultValue={updateData.document_reference_no || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Debit Note No"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Debit Note No"
                  style={{ height: "100px" }}
                  name="debit_note_no"
                  className="clear"
                  defaultValue={updateData.debit_note_no || ""}
                  onChange={handleChangeInput}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Total Amount"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="Total Amount"
                  name="total_amount"
                  className="clear"
                  defaultValue={updateData.total_amount || ""}
                  onChange={handleChangeInput}

                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingSelect"
                className="dropDown"
                label="Status"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  name="status"
                  placeholder="Status"
                  defaultValue={updateData.status || ""}
                  onChange={handleChangeInput}
                  className="clear"
                >
                  <option value="Raised" selected="selected">
                    Raised
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Partial Payment">Partial Payment</option>
                  <option value="Adjusted">Adjusted</option>
                  <option value="Payment Received">Payment Received</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Settled By Arutmin">Settled By Arutmin</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <h4 className="heading">RW Payable Receivable Lineitem
          </h4>
          <table id="rw-table">
            <thead>
              <tr>
                <th>Order</th>
                <th>Post Shipment Document</th>

                <th>Unit Rate</th>
                <th>Exchange Rate</th>
                <th>Quantity</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
            {formData.map((data, index) => (
          <tr key={index}>
            <td>
              <input
                type="text"
                name="order"
                placeholder="Order"
                className="form-control"
                value={data.order}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="post_ship_document"
                placeholder="Post Shipment Document"
                className="form-control clear"
                value={data.post_ship_document}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="unit_rate"
                placeholder="Unit Rate"
                className="form-control clear"
                value={data.unit_rate}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="exchange_rate"
                placeholder="Exchange Rate"
                className="form-control clear"
                value={data.exchange_rate}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="quantity"
                placeholder="Quantity"
                className="form-control clear"
                value={data.quantity}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="description"
                placeholder="Description"
                className="form-control clear"
                value={data.description}
                onChange={(e) => handleInputChange(index, e)}
              />
            </td>
            <td>
              <input
                type="text"
                name="amount"
                placeholder="Amount"
                className="form-control clear"
                value={data.amount}
                readOnly
              />
            </td>
            {renderNameFields(data, index)}
            <td>
              <FaTrashAlt className="cursor" onClick={() => handleRemoveField(index)} />
            </td>
          </tr>
        ))}
            </tbody>
          </table>
          <p className="text-center">
      <button type="button " className="add-line-item" onClick={handleAddField}>
        Add Line Item
      </button>
      <button className="add-line-item" onClick={handleSubmit}>Save</button>
      </p>
    </div>
    <p></p><p></p>
    <Footer/>
    </div>
  );
}

export default Postrwpaydetails;
