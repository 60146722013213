import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery'
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams, Link, useNavigate  } from "react-router-dom";
function  Addpreshipment(props) {
    const [one, setOne] = useState(new Date());


  const navigate=useNavigate();
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handlestartDateChange = (date) => {
    setStartDate(date);
  };

  const [startDate1, setstartDate1] = useState(null);
  const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
  const handlestartDateChange1 = (date) => {
    setstartDate1(date);
  };
  const [startDate2, setstartDate2] = useState(null);
  const birthDate2 = startDate2 ? `${startDate2.getDate().toString().padStart(2, '0')}/${(startDate2.getMonth() + 1).toString().padStart(2, '0')}/${startDate2.getFullYear()}` : null;
  const handlestartDateChange2 = (date) => {
    setstartDate2(date);
  };
  const [startDate3, setstartDate3] = useState(null);
  const birthDate3 = startDate3 ? `${startDate3.getDate().toString().padStart(2, '0')}/${(startDate3.getMonth() + 1).toString().padStart(2, '0')}/${startDate3.getFullYear()}` : null;
  const handlestartDateChange3 = (date) => {
    setstartDate3(date);
  };

  const [startDate4, setstartDate4] = useState(null);
  const birthDate4 = startDate4 ? `${startDate4.getDate().toString().padStart(2, '0')}/${(startDate4.getMonth() + 1).toString().padStart(2, '0')}/${startDate4.getFullYear()}` : null;
  const handlestartDateChange4 = (date) => {
    setstartDate4(date);
  };
  const [startDate5, setstartDate5] = useState(null);
  const birthDate5 = startDate5 ? `${startDate5.getDate().toString().padStart(2, '0')}/${(startDate5.getMonth() + 1).toString().padStart(2, '0')}/${startDate5.getFullYear()}` : null;
  const handlestartDateChange5 = (date) => {
    setstartDate5(date);
  };

  const [startDate6, setstartDate6] = useState(null);
  const birthDate6 = startDate6 ? `${startDate6.getDate().toString().padStart(2, '0')}/${(startDate6.getMonth() + 1).toString().padStart(2, '0')}/${startDate6.getFullYear()}` : null;
  const handlestartDateChange6 = (date) => {
    setstartDate6(date);
  };
  const [startDate7, setstartDate7] = useState(null);
  const birthDate7 = startDate7 ? `${startDate7.getDate().toString().padStart(2, '0')}/${(startDate7.getMonth() + 1).toString().padStart(2, '0')}/${startDate7.getFullYear()}` : null;
  const handlestartDateChange7 = (date) => {
    setstartDate7(date);
  };
  const [startDate8, setstartDate8] = useState(null);
  const birthDate8 = startDate8 ? `${startDate8.getDate().toString().padStart(2, '0')}/${(startDate8.getMonth() + 1).toString().padStart(2, '0')}/${startDate8.getFullYear()}` : null;
  const handlestartDateChange8 = (date) => {
    setstartDate8(date);
  };
  const [startDate9, setstartDate9] = useState(null);
  const birthDate9 = startDate9 ? `${startDate9.getDate().toString().padStart(2, '0')}/${(startDate9.getMonth() + 1).toString().padStart(2, '0')}/${startDate9.getFullYear()}` : null;
  const handlestartDateChange9 = (date) => {
    setstartDate9(date);
  };
  const [startDate10, setstartDate10] = useState(null);
  const birthDate10 = startDate10 ? `${startDate10.getDate().toString().padStart(2, '0')}/${(startDate10.getMonth() + 1).toString().padStart(2, '0')}/${startDate10.getFullYear()}` : null;
  const handlestartDateChange10 = (date) => {
    setstartDate10(date);
  };



    const [thirteen, setThirteen] = useState(new Date());
    const thirteens =
       thirteen.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       thirteen.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       thirteen.toLocaleDateString("en-US", { day: "2-digit" });
     const Date13 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));

               const [twele, setTwele] = useState(new Date());
 const tweles =
    twele.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    twele.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    twele.toLocaleDateString("en-US", { day: "2-digit" });
  const Date12 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

    const [nine, setNine] = useState(new Date());
    const nines =
       nine.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       nine.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       nine.toLocaleDateString("en-US", { day: "2-digit" });
     const Date9 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));

    const [eight, setEight] = useState(new Date());
    const eights =
       eight.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       eight.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       eight.toLocaleDateString("en-US", { day: "2-digit" });
     const Date8 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));

    const [seven, setSeven] = useState(new Date());
    const sevens =
       seven.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       seven.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       seven.toLocaleDateString("en-US", { day: "2-digit" });
     const Date7 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));
    const [six, setSix] = useState(new Date());
    const sixs =
       six.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       six.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       six.toLocaleDateString("en-US", { day: "2-digit" });
     const Date6 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));
    const [five, setFive] = useState(new Date());
    const fives =
       five.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       five.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       five.toLocaleDateString("en-US", { day: "2-digit" });
     const Date5 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));

    const [four, setFour] = useState(new Date());
    const fours =
       four.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       four.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       four.toLocaleDateString("en-US", { day: "2-digit" });
     const Date4 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));
    const [three, setThree] = useState(new Date());
    const threes =
       three.toLocaleDateString("en-US", { year: "numeric" }) +
       "-" +
       three.toLocaleDateString("en-US", { month: "2-digit" }) +
       "-" +
       three.toLocaleDateString("en-US", { day: "2-digit" });
     const Date3 = forwardRef(({ value, onClick, onChange }, ref) => (
       <input
         value={value}
         className="example-custom-input"
         onClick={onClick}
         onChange={onChange}
         name=""
         ref={ref}
       ></input>
     ));
    const [two, setTwo] = useState(new Date());

    const twos =
    two.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    two.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    two.toLocaleDateString("en-US", { day: "2-digit" });
  const Date2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="renewal_date"
      ref={ref}
    ></input>
  ));
  const [check,setCheck1]=useState(false)

  const [ten, setTen] = useState(new Date());
  const tens =
     ten.toLocaleDateString("en-US", { year: "numeric" }) +
     "-" +
     ten.toLocaleDateString("en-US", { month: "2-digit" }) +
     "-" +
     ten.toLocaleDateString("en-US", { day: "2-digit" });
   const Date10 = forwardRef(({ value, onClick, onChange }, ref) => (
     <input
       value={value}
       className="example-custom-input"
       onClick={onClick}
       onChange={onChange}
       name=""
       ref={ref}
     ></input>
   ));

   const [seventeen, setSeventeen] = useState(new Date());
   const seventeens =
      seventeen.toLocaleDateString("en-US", { year: "numeric" }) +
      "-" +
      seventeen.toLocaleDateString("en-US", { month: "2-digit" }) +
      "-" +
      seventeen.toLocaleDateString("en-US", { day: "2-digit" });
    const Date17 = forwardRef(({ value, onClick, onChange }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        name=""
        ref={ref}
      ></input>
    ));
   const [eleven, setEleven] = useState(new Date());
   const elevens =
      eleven.toLocaleDateString("en-US", { year: "numeric" }) +
      "-" +
      eleven.toLocaleDateString("en-US", { month: "2-digit" }) +
      "-" +
      eleven.toLocaleDateString("en-US", { day: "2-digit" });
    const Date11 = forwardRef(({ value, onClick, onChange }, ref) => (
      <input
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        name=""
        ref={ref}
      ></input>
    ));
   const params = useParams()
  const [value, onChange] = useState(new Date());
  const [accountNames, setAccountName]=useState([]);
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(window_height)
  const [rwoodbank, setRwoodbank]=useState([]);

  useEffect(() => {

    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_bank;
      // console.log(data)
      setRwoodbank(data);

    } catch (error) {
      console.error(error);
    }
  };

  const [bank, setBank]=useState([]);

  useEffect(() => {

    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodbank', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_bank;
    //   console.log(data)
      setBank(data);

    } catch (error) {
      console.error(error);
    }
  };

  const [user, setUser]=useState([]);

  useEffect(() => {

    fetchData3();
  }, [decryptedAccessToken]);

  const fetchData3 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/users', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setUser(data);

    } catch (error) {
      console.error(error);
    }
  };

  const [accounts, setAccounts]=useState([]);

  useEffect(() => {

    fetchData4();
  }, [decryptedAccessToken]);

  const fetchData4 = async () => {
    try {
      const response = await axios.get(API_URL+'get/buyer', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setAccounts(data);

    } catch (error) {
      console.error(error);
    }
  };

  $(".contactAccount").css({"min-height":(window_height)+"px"})
const id=params.id
     const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{
        const responseData={
          locked:true,
          rwood_bank:data.rwood_bank,
          currency:data.currency,
          exchange_rate:parseFloat(data.exchange_rate),
          master_lc_no:data.master_lc_no,
          other_bank_cost:data.other_bank_cost,
          adding_confirmation_date:birthDate,
          master_lc_expiry_date_1:birthDate1,
          master_lc_reviewed_date:birthDate2,
          master_lc_shipment_date_1:birthDate3,
          master_lc_issue_date:birthDate4,
          master_lc_presentation_date:birthDate5,
          interest:data.interest,
          vat_on_bank_charges:data.vat_on_bank_charges,
          bill_handling_charges:data.bill_handling_charges,
          master_lc_quantity:parseInt(data.master_lc_quantity),
          master_lc_amount:parseFloat(data.master_lc_amount),
          master_lc_unit_price:parseFloat(data.master_lc_unit_price),
          master_lc_presentation_days:parseFloat(data.master_lc_presentation_days),
          child_lc_issued_date:birthDate4,
          child_lc_expiry_date:birthDate5,
          child_lc_shipment_date:birthDate6,
          master_lc_tolerance:parseFloat(data.master_lc_tolerance),
          child_lc_amount:parseFloat(data.child_lc_amount),
          vat_on_bank_charges:parseFloat(data.vat_on_bank_charges),
          child_lc_tolerance:parseFloat(data.child_lc_tolerance),
          child_lc_quantity:parseFloat(data.child_lc_quantity),
          adding_confrimation_days:parseFloat(data.adding_confrimation_days),
          adding_confirmation_charges:parseFloat(data.adding_confirmation_charges),
          interest:parseFloat(data.interest),
          bill_handling_charges:parseFloat(data.bill_handling_charges),
          libor_rate_n_bank_cost_rate:parseFloat(data.libor_rate_n_bank_cost_rate),
          other_bank_cost:parseFloat(data.other_bank_cost),
          child_lc_unit_price:parseFloat(data.child_lc_unit_price),
          child_lc_presentation_days:parseFloat(data.child_lc_presentation_days),
          lc_transfer_cost:parseFloat(data.lc_transfer_cost),
          days_to_add_into_child_lc_period:parseFloat(data.days_to_add_into_child_lc_period),
          days_to_add_into_adding_confirmation_day:parseFloat(data.days_to_add_into_adding_confirmation_day),
          child_lc_prepared_date:birthDate7,
          child_lc_reviewed_date:birthDate8,
          child_lc_application_date:birthDate9,
          child_lc_place_of_expiry:data.child_lc_place_of_expiry,
          child_lc_applicant:data.child_lc_applicant,
          child_lc_notify_party:data.child_lc_notify_party,
          child_lc_consignee:data.child_lc_consignee,
          child_lc_destination:data.child_lc_destination,
          child_lc_status:data.child_lc_status,
          child_lc_port_of_loading:data.child_lc_port_of_loading,
          child_lc_quantity:data.child_lc_quantity,
          child_lc_unit_price:data.child_lc_unit_price,
          child_lc_prepared_by:data.child_lc_prepared_by,
          child_lc_rwood_bank:data.child_lc_rwood_bank,
          child_lc_shipper_bank:data.child_lc_shipper_bank,
          child_lc_reviewed_by:data.child_lc_reviewed_by,
          child_lc_presentation_days:data.child_lc_presentation_days,
          lc_discounting_application_date:birthDate10,
          days_to_add_into_child_lc_period:data.days_to_add_into_child_lc_period,
          days_to_add_into_adding_confirmation_day:data.days_to_add_into_adding_confirmation_day,
          libor_rate_n_bank_cost_rate:data.libor_rate_n_bank_cost_rate,
           other_bank_cost:data.other_bank_cost};
          console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+'add/preshipment/'+id, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response)
          if(response.message==='Rwood bank record needed'){
            toast.success('Rwood bank record needed', {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });

          }else{
          toast.success('Preshipment Added Successfully', {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          setTimeout(() => {
            navigate("/shipment/preshipment-details/"+response.preshipment_id);
            reset();
          }, 300);
        }
        })

      }
};

  return (

    <div className="contactAccount preshipment" id='contacts'>
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Preshipment</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Order / Add Preshipment</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
   <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/order/view-order-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

   <Row className="mb-3">

<div className='col-md-12'>
<h4 className='heading'>Information</h4>
  </div>
   <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
      <Row className="mb-3">
</Row>
      <div className='col-md-12'>
      <h4 className='heading'>Master LC</h4>
        </div>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Rwood Bank Master">
    <Form.Select aria-label="Floating label select example"
    name="rwood_bank"
    placeholder="Rwood bank"
    {...register("rwood_bank", { required: false })}
    >
      <option value='' >Select</option>

       {rwoodbank.length>0?(
        <>
      {
          rwoodbank.map((x)=>{
              return(
                  <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}

    </Form.Select>
    {errors.rwood_bank && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Commodity">
    <Form.Select aria-label="Floating label select example"
    name="commodity"
    id='account_name'
    placeholder="Commodity"
    defaultValue=""
    {...register("commodity", { required:false })}
    >
     <option value="">--None--</option><option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
    </Form.Select>
    {errors.commodity && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Exchange rate"
              name="exchange_rate"
              {...register("exchange_rate", {valueAsNumber:true, required: false })}
            />
            {errors.exchange_rate && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Master LC Reviewed By">
    <Form.Select aria-label="Floating label select example"
    name="master_lc_reviewed_by"
    placeholder="Master LC Reviewed By"
    {...register("master_lc_reviewed_by", { required: false })}
    >
      <option value='' >Select</option>


{rwoodbank.length>0?(
        <>
      {
          rwoodbank.map((x)=>{
              return(
                  <option value={x.rwood_bank_name}>{x.rwood_bank_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.master_lc_reviewed_by && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Master LC Status">
    <Form.Select aria-label="Floating label select example"
    name="master_lc_status"
    placeholder="Master LC Status"
    {...register("master_lc_status", { required: false })}
    >
     <option value="">--None--</option><option value="Pending">Pending</option><option value="Draft">Draft</option><option value="Issued">Issued</option>

    </Form.Select>
    {errors.master_lc_status && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master LC Expiry Date 1"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate1}
        onChange={handlestartDateChange1}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master LC Reviewed Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate2}
        onChange={handlestartDateChange2}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Place of Expiry"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Place of Expiry"
            name="master_lc_place_of_expiry"
            {...register("master_lc_place_of_expiry", { required: false })}
            />
             {errors.master_lc_place_of_expiry && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master LC Shipment date1"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate3}
        onChange={handlestartDateChange3}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Issuing Bank"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Issuing Bank"
            name="master_lc_issuing_bank"
            {...register("master_lc_issuing_bank", { required: false })}
            />
             {errors.master_lc_issuing_bank && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Adding Confirmation Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate}
        onChange={handlestartDateChange}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>  <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Currency">
    <Form.Select aria-label="Floating label select example"
    name="currency"
    placeholder="Currency"
    {...register("currency", { required: false })}
    >
      <option value='' >Select</option>
      <option value='USD' >USD</option>
      <option value='IDR' >IDR</option>

    </Form.Select>
    {errors.currency && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Issuing Bank Swift Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Issuing Bank Swift Code"
            name="issuing_bank_swift_code"
            {...register("issuing_bank_swift_code", { required: false })}
            />
             {errors.issuing_bank_swift_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Unit Price"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Unit Price"
            name="master_lc_unit_price"
            {...register("master_lc_unit_price", { required: false })}
            />
             {errors.master_lc_unit_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Master LC Applicant">
    <Form.Select aria-label="Floating label select example"
    name="master_lc_applicant"
    placeholder="Master LC Applicant"
    {...register("master_lc_applicant", { required: false })}
    >
      <option value='' >Select</option>

       {accounts.length>0?(
        <>
      {
          accounts.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.master_lc_applicant && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>


        <Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Quantity"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Quantity"
            name="master_lc_quantity"
            {...register("master_lc_quantity", {valueAsNumber:true, required:true })}
            />
             {errors.master_lc_quantity && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Amount"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Amount"
            name="master_lc_amount"
            {...register("master_lc_amount", {valueAsNumber:true, required: false })}
            />
             {errors.master_lc_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Quantity Tolerance"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Quantity Tolerance"
            name="master_lc_quantity_tolerance"
            {...register("master_lc_quantity_tolerance", {valueAsNumber:true, required: false })}
            />
             {errors.master_lc_quantity_tolerance && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Tolerance"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Tolerance"
            name="master_lc_tolerance"
            {...register("master_lc_tolerance", { required: false })}
            />
             {errors.master_lc_tolerance && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Price Basis"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="price_basis"
                            placeholder="Price Basis"

                             {...register("price_basis", { required: false })}
                          >
                            <option value="">--None--</option>
                            <option value="GCV (ARB)">GCV (ARB)</option>
                            <option value="GCV (ADB)">GCV (ADB)</option>
                            <option value="NCV (ARB)">NCV (ARB)</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
        <Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Consignee"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Consignee"
            name="master_lc_consignee"
            {...register("master_lc_consignee", { required: false })}
            />
             {errors.master_lc_consignee && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Notify Party1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Notify Party1"
            name="notify_party_1"
            {...register("notify_party_1", { required: false })}
            />
             {errors.notify_party_1 && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Destination"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Destination"
            name="master_lc_destination"
            {...register("master_lc_destination", { required: false })}
            />
             {errors.master_lc_destination && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Notify Party"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Notify Party"
            name="notify_party"
            {...register("notify_party", { required: false })}
            />
             {errors.notify_party && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6" id=''>
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Port of Loading"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Port of Loading"
            name="master_lc_port_of_loading"
            {...register("master_lc_port_of_loading", { required: false })}
            />
             {errors.master_lc_port_of_loading && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="LC Type">
    <Form.Select aria-label="Floating label select example"
    name="lc_type"
    placeholder="LC Type"
    {...register("lc_type", { required: false })}
    >
     <option value="">--None--</option><option value="Transfer LC">Transfer LC</option><option value="B2B LC">B2B LC</option>
    </Form.Select>
    {errors.lc_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
               </Row>
      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Master LC Issued</h4>
        </div>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master LC Issue Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate4}
        onChange={handlestartDateChange4}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC Presentation Days"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC Presentation Days"
            name="master_lc_presentation_days"
            {...register("master_lc_presentation_days", {valueAsNumber:true, required: false })}
            />
             {errors.master_lc_presentation_days && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Master LC No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Master LC No"
            name="master_lc_no"
            {...register("master_lc_no", { required:true })}
            />
             {errors.master_lc_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master LC Presentation Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate5}
        onChange={handlestartDateChange5}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>
      </Row>

      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Child LC</h4>
        </div>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC No"
            name="child_lc_no"
            {...register("child_lc_no", { required: false })}
            />
             {errors.child_lc_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Child LC Status">
    <Form.Select aria-label="Floating label select example"
    name="child_lc_status"
    placeholder="Child LC Status"
    {...register("child_lc_status", { required: false })}
    >
      <option value="">--None--</option><option value="Draft">Draft</option><option value="Issued">Issued</option><option value="Pending">Pending</option>
    </Form.Select>
    {errors.child_lc_status && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Issue Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate6}
        onChange={handlestartDateChange6}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Port of Loading"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Port of Loading"
            name="child_lc_port_of_loading"
            {...register("child_lc_port_of_loading", { required: false })}
            />
             {errors.child_lc_port_of_loading && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Expiry Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate7}
        onChange={handlestartDateChange7}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Shipment Date	"
                  className="mb-3 date-picker"
                >
                  <DatePicker
              selected={startDate8}
        onChange={handlestartDateChange8}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Place of Expiry"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Place of Expiry"
            name="child_lc_place_of_expiry"
            {...register("child_lc_place_of_expiry", { required: false })}
            />
             {errors.child_lc_place_of_expiry && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Quantity"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Quantity"
            name="child_lc_quantity"
            {...register("child_lc_quantity", {valueAsNumber:true, required: false })}
            />
             {errors.child_lc_quantity && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Child LC Applicant">
    <Form.Select aria-label="Floating label select example"
    name="child_lc_applicant"
    placeholder="Child LC Applicant"
    {...register("child_lc_applicant", { required: false })}
    >
      <option value='' >Select</option>

       {accounts.length>0?(
        <>
      {
          accounts.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.child_lc_applicant && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Unit Price"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Unit Price"
            name="child_lc_unit_price"
            {...register("child_lc_unit_price", {valueAsNumber:true, required: false })}
            />
             {errors.child_lc_unit_price && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Amount"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Amount"
            name="child_lc_amount"
            {...register("child_lc_amount", {valueAsNumber:true, required: false })}
            />
             {errors.child_lc_amount && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="child LC prepared by"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="child LC prepared by"
            name="child_lc_prepared_by"
            {...register("child_lc_prepared_by", {valueAsNumber:true, required: false })}
            />
             {errors.child_lc_prepared_by && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Child LC Prepared By" >
    <Form.Select aria-label="Floating label select example"
    name="child_lc_prepared_by"
    placeholder="Child LC Prepared By"
    {...register("child_lc_prepared_by", { required: false })}
    >
      <option value='' >Select</option>

        {user.length>0?(
        <>
      {
          user.map((x)=>{
              return(
                  <option value={x.username}>{x.username}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.child_lc_prepared_by && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>  */}
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Notify Party"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Notify Party"
            name="child_lc_notify_party"
            {...register("child_lc_notify_party", { required: false })}
            />
             {errors.child_lc_notify_party && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Prepared Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate9}
        onChange={handlestartDateChange9}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Consignee"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Consignee"
            name="child_lc_consignee"
            {...register("child_lc_consignee", {required: false })}
            />
             {errors.child_lc_consignee && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Rwood Bank"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Rwood Bank"
            name="child_lc_rwood_bank"
            {...register("child_lc_rwood_bank", {valueAsNumber:true, required: false })}
            />
             {errors.child_lc_rwood_bank && <span>Required</span>}
          </FloatingLabel>
        </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Destination"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Destination"
            name="child_lc_destination"
            {...register("child_lc_destination", {required: false })}
            />
             {errors.child_lc_destination && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Child LC Shipper Bank"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Child LC Shipper Bank"
            name="child_lc_shipper_bank"
            {...register("child_lc_shipper_bank", {required: false })}
            />
             {errors.child_lc_shipper_bank && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Child  LC Reviewed By">
    <Form.Select aria-label="Floating label select example"
    name="child_lc_reviewed_by"
    placeholder="Child LC Reviewed By"
    {...register("child_lc_reviewed_by", { required: false })}
    >
      <option value='' >Select</option>

{user.length>0?(
        <>
        {
          user.map((x)=>{
              return(
                  <option value={x.username}>{x.username}</option>
              )
          })
       }

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.child_lc_reviewed_by && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Reviewed Date"
                  className="mb-3 date-picker"
                >
                   <DatePicker
              selected={startDate10}
        onChange={handlestartDateChange10}

        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                </FloatingLabel>
              </Form.Group>

      </Row>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>LC Application</h4>
</div>
  <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Application Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={twele}
                    onChange={(date) => setTwele(date)}
                    customInput={<Date12 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="LC Discounting Application Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={thirteen}
                    onChange={(date) => setThirteen(date)}
                    customInput={<Date13 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Child LC Presentation Days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Child LC Presentation Days"
                    name="child_lc_presentation_days"
                    {...register("child_lc_presentation_days", {
                        valueAsNumber:true,
                      required: false,
                    })}
                  />
                  {errors.child_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>



        </Row>

        <Row className="mb-3">

<div className='col-md-12'>
<h4 className='heading'>LC Costing</h4>
</div>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Days to add into Child LC Period"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Days to add into Child LC Period"
              name="days_to_add_into_child_lc_period"
              {...register("days_to_add_into_child_lc_period", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.days_to_add_into_child_lc_period && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Libor Rate n Bank Cost Rate"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Libor Rate n Bank Cost Rate"
              name="libor_rate_n_bank_cost_rate"
              {...register("libor_rate_n_bank_cost_rate", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.libor_rate_n_bank_cost_rate && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Days to add into Adding Confirmation day"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Days to add into Adding Confirmation day"
              name="days_to_add_into_adding_confirmation_day"
              {...register("days_to_add_into_adding_confirmation_day", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.days_to_add_into_adding_confirmation_day && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Interest"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Interest"
              name="interest"
              {...register("interest", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.interest && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LC Transfer Cost"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="LC Transfer Cost"
              name="lc_transfer_cost"
              {...register("lc_transfer_cost", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.lc_transfer_cost && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Other Bank Cost"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Other Bank Cost"
              name="other_bank_cost"
              {...register("other_bank_cost", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.other_bank_cost && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Adding Confirmation charges"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Adding Confirmation charges"
              name="adding_confirmation_charges"
              {...register("adding_confirmation_charges", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.adding_confirmation_charges && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="VAT on Bank Charges"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="VAT on Bank Charges"
              name="vat_on_bank_charges"
              {...register("vat_on_bank_charges", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.vat_on_bank_charges && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Bill Handling Charges"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Bill Handling Charges"
              name="bill_handling_charges"
              {...register("bill_handling_charges", {
                  valueAsNumber:true,
                required: false,
              })}
            />
            {errors.bill_handling_charges && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
  </Row>
  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/order/view-order-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>


      <div className='contact-success'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default Addpreshipment;
